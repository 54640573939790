import React, { Fragment } from 'react';
import TweenMax from 'gsap/all';
import styled, { css } from 'styled-components';
import isMobile from '../utils/isMobile';
import * as COLOURS from '../constants/colours';

const CursorOuter = styled.div`
  ${props => props.isVisible && css`
      background: ${props => props.theme.slate};
      border-radius: 50%;
      height: 50px;
      opacity: .2;
      pointer-events: none;
      width: 50px;
      position: fixed;
      left: 0px;
      top: 0px;
      z-index: 99999999;

      line-height: 0px;
      letter-spacing: 0.12em;
      text-transform: uppercase;
      text-align: center;
      color: ${props => props.theme.graphite};
      font-size: 12px;
      font-weight: bold;
      display: flex;
      justify-content: center;
      flex-direction: column;

      & > h1 span {
        color: red;
      }
  `}
 `

 const CursorInner = styled.div`
 ${props => props.isVisible && css`
      background-color: ${props => props.theme.vision};
      border-radius: 50%;
      height: 5px;
      pointer-events: none;
      position: fixed;
      width: 5px;
      z-index: 999;
      left: -3px;
      top: -3px;
      z-index: 99999999;
  `}
`

class Cursor extends React.Component {

  componentDidMount() {

    this.isMob = isMobile(); 

    if (!this.isMob) {
      this.initCursor();
      this.initHovers();
    }
  }

  initCursor() {
    this.outerCursor = document.querySelector('.circle-cursor--outer');
    this.innerCursor = document.querySelector('.circle-cursor--inner');

    this.outerCursorBox = this.outerCursor.getBoundingClientRect();
    this.outerCursorSpeed = 0;
    this.clientX = -100;
    this.clientY = -100;
    this.showCursor = false;

    const unveilCursor = () => {
      TweenMax.set(this.innerCursor, {
        x: this.clientX,
        y: this.clientY
      });
      TweenMax.set(this.outerCursor, {
        x: this.clientX - this.outerCursorBox.width / 2,
        y: this.clientY - this.outerCursorBox.height / 2
      });
      setTimeout(() => {
        this.outerCursorSpeed = 0.2;
      }, 100);
      this.showCursor = true;
    };
    document.addEventListener('mousemove', unveilCursor);

    document.addEventListener('mousemove', e => {
      this.clientX = e.clientX;
      this.clientY = e.clientY;
    });

    const render = () => {
      TweenMax.set(this.innerCursor, {
        x: this.clientX,
        y: this.clientY
      });
      if (!this.isStuck) {
        TweenMax.to(this.outerCursor, this.outerCursorSpeed, {
          x: this.clientX - this.outerCursorBox.width / 2,
          y: this.clientY - this.outerCursorBox.height / 2
        });
      }
      if (this.activeElement) {
        TweenMax.to(this.activeElement, 0.2, {
          '--x': `${this.clientX - this.activeElementBox.left}px`,
          '--y': `${this.clientY - this.activeElementBox.top}px`,
          '--size': `50px`
        });
      }
      if (this.showCursor) {
        document.removeEventListener('mousemove', unveilCursor);
      }
      requestAnimationFrame(render);
    };
    requestAnimationFrame(render);
  }

  initHovers() {

    // MOUSE INTERACTION ON SELECTED ELEMENTS
    const handleMouseEnter = e => {
      this.activeElement = e.currentTarget;
      this.activeElementBox = this.activeElement.getBoundingClientRect();

      this.outerCursorOriginals = {
        height: this.outerCursorBox.height,
        width: this.outerCursorBox.width
      };

      TweenMax.to(this.outerCursor, this.outerCursorSpeed, {
        height: this.outerCursorBox.height * 2,
        left: -(this.outerCursorBox.width / 2),
        opacity: 0.4,
        top: -(this.outerCursorBox.height / 2),
        width: this.outerCursorBox.width * 2,
      });
    };

    const handleMouseLeave = () => {

      TweenMax.to(this.activeElement, 0.2, {
        '--size': '0px'
      });
      
      this.activeElement = null;

      TweenMax.to(this.outerCursor, this.outerCursorSpeed, {
        left: 0,
        opacity: 0.2,
        top: 0,
        height: this.outerCursorOriginals.height,
        width: this.outerCursorOriginals.width,
        zIndex: -1
      });
    };

    const linkItems = document.querySelectorAll('.mouse-int');

    linkItems.forEach(item => {
      item.addEventListener('mouseenter', handleMouseEnter);
      item.addEventListener('mouseleave', handleMouseLeave);
    });


    // MOUSE INTERACTION ON MAIN NAVIGATION 
    const mainNavMouseEnter = e => {
      this.isStuck = true;

      const target = e.currentTarget;
      const box = target.getBoundingClientRect();

      this.outerCursorSpeed = 0;

      TweenMax.set(this.innerCursor, { opacity: 0 });

      this.outerCursorOriginals = {
        height: this.outerCursorBox.height,
        width: this.outerCursorBox.width
      };

      TweenMax.to(this.outerCursor, 0.2, {
        backgroundColor: 'transparent',
        opacity: 1,
        borderRadius: '50px',
        height: box.height,
        width: box.width,
        x: box.left,
        y: box.top
      });
    };

    const mainNavMouseLeave = () => {
      this.isStuck = false;

      this.outerCursorSpeed = 0.2;

      TweenMax.set(this.innerCursor, { opacity: 1 });

      this.outerCursor.innerHTML = '';

      TweenMax.to(this.outerCursor, 0.2, {
        backgroundColor: COLOURS.SLATE,
        opacity: 0.2,
        height: this.outerCursorOriginals.height,
        width: this.outerCursorOriginals.width,
      });
    };

    const mainNavLinks = document.querySelectorAll('.main-nav-int');
    mainNavLinks.forEach(item => {
      item.addEventListener('mouseenter', mainNavMouseEnter);
      item.addEventListener('mouseleave', mainNavMouseLeave);
    });
  }

    render() {
        return(
            <Fragment>
                <CursorOuter className={`circle-cursor--outer`} id={`cursor`} isVisible={!this.isMob} />
                <CursorInner className={`circle-cursor--inner`} isVisible={!this.isMob} />
            </Fragment>
        )
    }
}

export default Cursor;
