import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { CloseButton } from '../StyledComponents';
import { DEVICES } from '../../constants/breakpoints';

const Wrapper = styled.div`
    background-color: rgba(31, 31, 33, .7);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999;
`

const InnerWrapper = styled.div`
    background-color: ${props => props.theme.vivid};
    left: 50%;
    max-height: 80%;
    -ms-overflow-style: none;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 20px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 80%;

    @media ${DEVICES.TABLET} {
        padding: 25px;
        width: 60%;
    }

    @media ${DEVICES.TABLET_LANDSCAPE} {
        max-width: 750px;
        padding: 50px;
        width: 50%;
    }
`

const CloseBtn = styled(CloseButton)`
    position: absolute;
    right: 30px;
    top: 30px;
    
    @media ${DEVICES.LAPTOP} {
        right: 50px;
        top: 50px;
    }
`

const Modal = forwardRef(({
    children,
    closeModal
}, ref) => (
    <Wrapper>
        <InnerWrapper ref={ref}>
            <CloseBtn invert onClick={closeModal} />
            {children}
        </InnerWrapper>
    </Wrapper>
));

export default Modal;