import React from 'react';
import Section from '../components/Section';
import Footer from '../components/Footer';
import { Header } from '../components/StyledComponents';

const PredictionsDisclaimer = () => (
    <>
        <Section>
            <Header large>Predictions Disclaimer</Header>
            <h1>
                Terms & Conditions of Use of Vesparum Capital's Equity Capital Markets Predictive Information on Social
                Media
            </h1>
            <p>
                You have been directed to this Site after viewing predictive analysis made by Vesparum Capital Pty Ltd
                (ACN 160 118 141, AFSL 432092) with respect to Australian equity capital markets events and published by
                Vesparum Capital Pty Ltd on social media (eg. Twitter) ("Predictive Information").
            </p>
            <p>
                The Predictive Information has been prepared in connection with the dissemination of specific, publicly
                known equity capital markets information and the predictive analysis of Vesparum Capital Pty Ltd in
                connection with that information. In accessing the Predictive Information, you acknowledge and agree to
                the terms set out below.
            </p>
            <p>
                The Predictive Information contains summary information about a specific, publicly announced proposed
                equity capital raising by an ASX listed entity. It also contains the prediction of Vesparum Capital Pty
                Ltd regarding the outcome of the announced equity capital raising. It is important to note that the
                Predictive Information is current only as at the time it is published (as specified on the relevant
                social media platform on which the Predictive Information is published). The Predictive Information has
                been prepared without consideration of the needs or investment objectives of any particular person or
                entity. The Predictive Information is provided to and intended for Australian-based wholesale clients
                only (and each such person or entity who accesses such information, the “Recipient”).
            </p>
            <p>
                The information in and used to prepare the Predictive Information has been obtained from and is based on
                sources believed by Vesparum Capital Pty Ltd to be reliable. Certain market and industry data used in
                connection with the Predictive Information may have been obtained from research, surveys or studies
                conducted by third parties, including industry or general publications. The Predictive Information does
                not purport to contain all the information that the Recipient may desire or need, and has been prepared
                on the basis of publicly available information. Neither Vesparum Capital Pty Ltd nor any of its related
                bodies corporate, affiliates, employees, representatives or other agents (together, “Vesparum”) have
                independently verified any such market or industry data provided by third parties or industry or general
                publications, nor do they assume any responsibility for the independent verification of such
                information. No warranty, express or implied, is made as to the fairness, accuracy, correctness,
                completeness or adequacy of this information.
            </p>
            <p>
                The Predictive Information is provided without any liability (whether arising in contract, in tort or
                otherwise) whatsoever to Vesparum in respect of or in connection with any of its content, including any
                error or omission or any loss or damage (whether direct, indirect, consequential or otherwise) that may
                arise from any such error or omission. It may not be used by the Recipient (or any other person or
                entity) for any purpose other than as specifically contemplated by written agreement with Vesparum. In
                all cases, the Recipient (and any other person or entity who receives the Predictive Information) should
                conduct its own investigations and analysis with regards to its contents or any other matter to which it
                may relate, including by obtaining specialist professional advice. In particular, the Predictive
                Information (or any of its contents) is not intended to constitute legal, tax, regulatory, accounting or
                other specialist professional advice.
            </p>
            <p>
                To the fullest extent permitted by law, Vesparum disclaims all responsibility for the contents of the
                Predictive Information and for any reliance that the Recipient (or any other person or entity) may seek
                to place upon it. No representation or warranty is made by Vesparum as to the accuracy, reliability or
                completeness any information contained in the Predictive Information, nor that any projection, estimate,
                forecast, target, calculation, forward-looking statement or assumption in the Predictive Information
                (together, "Forward Looking Statements") should or will be achieved. Forward Looking Statements are
                provided as a general guide only and should not be relied upon as an indication or guarantee of future
                performance. They may involve known and unknown risks, contingencies and uncertainties and other
                factors, many of which cannot be predicted by Vesparum. The Recipient (or any other person or entity) is
                therefore cautioned not to place undue reliance on Forward Looking Statements.
            </p>
            <p>
                Except as required by law, Vesparum undertakes no obligation and assumes no responsibility to the
                Recipient (or any other person or entity) to update or supplement the Predictive Information (including
                any recommendation contained therein) or to correct or inform of any other matter arising or coming to
                its notice which may affect the Predictive Information, including any inaccuracies or omissions.
            </p>
            <p>
                Nothing contained in the Predictive Information constitutes giving investment or financial product
                advice (nor tax, accounting or legal advice) or any recommendation by Vesparum or an offer to enter into
                any transaction or investment.
            </p>
            <p>
                Under the Commonwealth Copyright Act 1968, the Predictive Information, being an original literary work
                produced in 2020, is an original work in which copyright subsists. Accordingly, all rights conferred and
                protected under the Copyright Act 1968 apply to the Predictive Information, and to Vesparum Capital Pty
                Ltd as owner of said copyright. Nothing in the contents of the Predictive Information or in the act of
                its distribution to the Recipient (or to any other person or entity) is to be construed as providing any
                licence for its reproduction, transmission, or storing in any form or by any means, or as a licence for
                showing or distributing the Presentation to any third party, and no part may be reproduced by any
                process nor may any other exclusive right be exercised, other than with the express prior permission of
                Vesparum Capital Pty Ltd.
            </p>
        </Section>
        <Footer visible />
    </>
);

export default PredictionsDisclaimer;
