import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const TitleLink = styled(Link)`
    color: ${props => props.theme[props.color]};
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 26px;
    letter-spacing: 0.12em;
    text-transform: uppercase;
    text-decoration: none;
`;

const TitleHash = styled.div`
    color: ${props => props.theme[props.color]};
    cursor: pointer;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 26px;
    letter-spacing: 0.12em;
    text-transform: uppercase;
    text-decoration: none;
`;

const LineBefore = styled.div`
    background: ${props => props.theme[props.color]};
    height: 2px;
    margin-right: 10px;
    transition: width 0.2s ease;
    width: 50px;
`;

const LineAfter = styled.div`
    background: ${props => props.theme[props.color]};
    height: 2px;
    margin-left: 10px;
    transition: width 0.2s ease;
    width: 0;
`;

const Wrapper = styled.div`
    align-self: ${props => props.align || 'inherit'};
    display: inline-block;
`;

const InnerWrapper = styled.div`
    align-items: center;
    display: flex;
    margin: ${props => props.customMargin || '25px 0'};

    &:hover {
        ${LineBefore} {
            width: 0;
            transition: width 0.2s ease;
        }
        ${LineAfter} {
            width: 50px;
            transition: width 0.2s ease;
        }
    }
`;

// TODO: make it possible to click on line before/after
const CtaButton = forwardRef(
    ({ align, children = 'Learn more', customMargin, color = 'graphite', textColor, hash, onClick, to }, ref) => {
        const scrollToSection = e => {
            const sectionName = e.target.getAttribute('name');
            const sectionDOM = document.getElementById(sectionName);
            if (sectionDOM) {
                const { offsetTop } = sectionDOM;

                window.scrollTo(0, offsetTop);
            }
        };

        return (
            <Wrapper align={align} ref={ref}>
                <InnerWrapper onClick={onClick} customMargin={customMargin}>
                    <LineBefore color={color} />
                    {hash && (
                        <TitleHash color={textColor ?? color} name={hash} onClick={scrollToSection}>
                            {children}
                        </TitleHash>
                    )}
                    {to && (
                        <TitleLink color={textColor ?? color} to={to || '#'}>
                            {children}
                        </TitleLink>
                    )}
                    <LineAfter color={color} />
                </InnerWrapper>
            </Wrapper>
        );
    },
);

export default CtaButton;
