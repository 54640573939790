import React, { useLayoutEffect, useContext, useState, useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';
import ReactHtmlParser from 'react-html-parser';
import { Context } from '../ContextState';
import Arrow from './Arrow';

const AccordionWrapper = styled.div`
    border-bottom: 1px solid ${props => (props.invert ? props.theme.graphite : props.theme.vivid)};
    color: ${props => (props.invert ? props.theme.graphite : props.theme.vivid)};
    margin-bottom: 25px;
`;

const Content = styled.div`
    color: ${props => (props.invert ? props.theme.graphite : props.theme.vivid)};
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    height: ${props => props.height}px;
    line-height: 26px;
    position: relative;
    transition: height 0.3s ease;

    ${props =>
        props.isOpen &&
        css`
            height: ${props => props.expandHeight}px;
            transition: height 0.3s ease;
        `}
`;

const ClippedContent = styled.div`
    display: inline;
`;

const RestOfContent = styled.div`
    display: inline;
    left: 0;
    opacity: ${props => (props.isOpen ? 1 : 0)};
    transition: opacity 0.3s ease;

    ${props =>
        props.isOpen &&
        css`
            transition: opacity 0.5s ease 0.2s;
        `}
`;

const Title = styled.div`
    margin-right: 5px;
    position: relative;

    &:after {
        background-color: ${props => props.theme.graphite};
        bottom: 0;
        content: '';
        display: block;
        height: 2px;
        left: 0;
        position: absolute;
        transition: width 0.2s ease;
        width: 0;
    }
`;

const ArrowWrapper = styled.div`
    transform: ${props => (props.isOpen ? 'rotate(0) scale(0.9)' : 'rotate(180deg) scale(0.9)')};
    transition: transform 0.3s ease;
`;

const ReadMoreWrapper = styled.div`
    display: inline-block;
`;

const ReadMore = styled.div`
    align-items: center;
    color: ${props => (props.invert ? props.theme.graphite : props.theme.vivid)};
    cursor: pointer;
    display: flex;
    font-size: 12px;
    font-style: normal;
    font-weight: bold;
    letter-spacing: 0.12em;
    line-height: 26px;
    margin-bottom: 25px;
    margin-top: 10px;
    position: relative;
    text-transform: uppercase;

    &:hover {
        ${Title} {
            &:after {
                width: 100%;
            }
        }
    }
`;

const Accordion = ({ children, introCopy = '', readMoreCopy = '', invert }) => {
    const restOfContent = useRef();
    const clippedContent = useRef();
    const fullContent = useRef();

    const { setUpdateAllSections } = useContext(Context);

    const [isOpen, setOpen] = useState(false);
    const [fullContentHeight, setFullContentHeight] = useState(0);
    const [clippedContentHeight, setClippedContentHeight] = useState(0);

    const setContentDimensions = () => {
        const fullContentHeight = fullContent.current.getBoundingClientRect().height;
        setFullContentHeight(fullContentHeight);
        const clippedContentHeight = clippedContent.current.getBoundingClientRect().height;
        setClippedContentHeight(clippedContentHeight);
    };

    useLayoutEffect(() => {
        setContentDimensions();

        window.addEventListener('resize', setContentDimensions);
        return () => window.removeEventListener('resize', setContentDimensions);
    }, []);

    const toggleExpand = () => {
        setOpen(!isOpen);
    };

    useEffect(() => {
        setUpdateAllSections(isOpen);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    return (
        <AccordionWrapper invert={invert}>
            {children}
            <Content
                height={clippedContentHeight}
                expandHeight={fullContentHeight + 30}
                invert={invert}
                isOpen={isOpen}
            >
                <ClippedContent isOpen={isOpen} ref={fullContent}>
                    <div ref={clippedContent}>
                        {introCopy.length > 0 && ReactHtmlParser(introCopy)}
                        {introCopy.length > 0 && !isOpen && '...'}
                    </div>
                    <RestOfContent isOpen={isOpen} ref={restOfContent}>
                        {readMoreCopy}
                    </RestOfContent>
                </ClippedContent>
            </Content>

            <ReadMoreWrapper>
                <ReadMore onClick={toggleExpand} invert={invert}>
                    <Title>Read More</Title>
                    <ArrowWrapper isOpen={isOpen}>
                        <Arrow invert={invert} />
                    </ArrowWrapper>
                </ReadMore>
            </ReadMoreWrapper>
        </AccordionWrapper>
    );
};

export default Accordion;
