import React, { useContext } from 'react';
import styled from 'styled-components';

import { Context } from '../../ContextState';
import Section from '../../components/Section';
import AnimatedCircles from '../../components/Animations/AnimatedCircles';
import { Description, Header, SubHeader } from '../../components/StyledComponents';
import CtaButton from '../../components/CtaButton';
import SmartLink from '../../components/SmartLink';

import { DEVICES } from '../../constants/breakpoints';

const AnimationGroup = styled.div`
    position: absolute;
    top: 55%;
    transform: translateY(-50%);
    width: 85%;
    z-index: 4;

    @media ${DEVICES.LAPTOP} {
        width: 72%;
    }
`;

const StyledHeader = styled(Header)`
    @media ${DEVICES.MOBILE_MAX} {
        font-size: 20px;
    }
`;

const StyledSubHeader = styled(SubHeader)`
    @media ${DEVICES.LAPTOP} {
        font-size: 28px;
    }

    @media ${DEVICES.MOBILE_MAX} {
        font-size: 15px;
    }
`;

const StyledDescription = styled(Description)`
    @media ${DEVICES.MOBILE_MAX} {
        font-size: 14px;
    }
`;

const CapitalSolutions = () => {
    const { activeSection } = useContext(Context);
    const inView = activeSection.name === 'capital-solutions';

    return (
        <Section minHeightWithLimit name="capital-solutions" displayName="Capital Solutions">
            <AnimatedCircles active={inView}>
                <AnimationGroup>
                    <StyledHeader underline>Capital solutions</StyledHeader>

                    <StyledSubHeader>
                        As part of the Vesparum Group, we are founder-led and backed by some of Australia’s leading
                        family offices, operators and investors.
                    </StyledSubHeader>

                    <StyledDescription>
                        We offer patient capital, which combines our balance sheet with the capital of our funding
                        partners. We also have access to additional third party capital through{' '}
                        <SmartLink to="/vesparum-capital" color="vivid">
                            Vesparum Capital
                        </SmartLink>
                        , the capital markets advisory division of the Vesparum Group.
                    </StyledDescription>

                    <StyledDescription>
                        Our capital solutions are designed for companies seeking to fund AI opportunities, with
                        flexibility to offer liquidity for founders, employees and other shareholders.
                    </StyledDescription>

                    <CtaButton color="vision" hash="ai-solutions">
                        AI solutions
                    </CtaButton>
                </AnimationGroup>
            </AnimatedCircles>
        </Section>
    );
};

export default CapitalSolutions;

// 🡺
