import React, { useContext } from 'react';
import styled, { css } from 'styled-components';
import { Context } from '../../ContextState';
import Section from '../../components/Section';
import { Description, Header } from '../../components/StyledComponents';
import DataScienceAnimation from '../../components/Animations/DataScience';
import { DEVICES } from '../../constants/breakpoints';

const ContentWrapper = styled.div`
    width: 100%;

    @media ${DEVICES.TABLET_LANDSCAPE} {
        width: 50%;
    }
`;

const StyledHeader = styled(Header)`
    @media ${DEVICES.TABLET} {
        opacity: 0;
        transform: translateY(20vh);
        transition: transform 0.6s ease-out, opacity 0.2s ease-out;

        ${props =>
            props.isActive &&
            css`
                opacity: 1;
                transform: translateY(0);
                transition: transform 0.6s ease-out 0.2s, opacity 0.2s ease-out 0.2s;
            `}
    }
`;

const StyledDescription = styled(Description)`
    @media ${DEVICES.TABLET} {
        opacity: 0;
        transform: translateY(20vh);
        transition: transform 0.6s ease-out, opacity 0.2s ease-out;

        ${props =>
            props.isActive &&
            css`
                opacity: 1;
                transform: translateY(0);
                transition: transform 0.6s ease-out 0.4s, opacity 0.2s ease-out 0.4s;
            `}
    }
`;

const WhatWeDo = () => {
    const { activeSection } = useContext(Context);
    const inView = activeSection.name === 'difference';

    return (
        <Section name="difference" displayName="difference">
            <ContentWrapper>
                <StyledHeader isActive={inView}>Differentiated by data</StyledHeader>

                <StyledDescription hero isActive={inView}>
                    Products and services informed by our proprietary database of 16,000+ ASX-listed capital raisings,
                    with predictive modelling based on 300+ transaction variables.
                </StyledDescription>
            </ContentWrapper>

            <DataScienceAnimation inView={inView} />
        </Section>
    );
};

export default WhatWeDo;
