import React from 'react';
import styled from 'styled-components';
import CtaButton from '../CtaButton';
import { DEVICES } from '../../constants/breakpoints';

const CardBackground = styled.div`
    background-color: ${props => props.theme.darkSlate};
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    padding: 25px;
    justify-content: space-between;

    @media ${DEVICES.TABLET} {
        padding: 40px;
        padding-bottom: 30px;
    }
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;

    @media ${DEVICES.TABLET_LANDSCAPE} {
        max-width: 420px;
        padding: 0;
        width: 90%;
    }

    @media ${DEVICES.LAPTOP} {
        max-width: 480px;
        width: 95%;
    }
`;

// eslint-disable-next-line react/prop-types
const TeamCard = ({ children, clickThrough, founderLink }) => (
    <Wrapper className="card-wrapper">
        <CardBackground>
            <div>{children}</div>
            <CtaButton align="flex-end" color="vision" onClick={clickThrough} to="#" customMargin="0 0 10px 0">
                {founderLink}
            </CtaButton>
        </CardBackground>
    </Wrapper>
);

export default TeamCard;
