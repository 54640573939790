import React, { useState } from 'react';
import styled from 'styled-components';

const TextField = styled.input`
    border: 0;
    border-bottom: 2px solid ${props => !props.isValid ? props.theme.error : props.filled ? props.theme.vision : props.theme.slate};
    width: 100%;
    
    &::placeholder {
        color: ${props => props.theme.slate};
    }
    
    &:focus {
        outline: 0;
        border-bottom: 2px solid ${props => !props.isValid ? props.theme.error : props.theme.vision};
    }
`

const useInput = ({ type, validation, placeholder }) => {
    const [ value, setValue ] = useState('');
    const [ isValid, setValid ] = useState(true);
    const [ isTouched, setTouched ] = useState(false);
    const [ isVisible, setVisible ] = useState(false);

    const handleChange = ({ target: { value } = {}, passedValue }) => {
        const newValue = value || passedValue;
        setValue(newValue);
        setTouched(true);
    }
    
    const validate = () => {

        // EMAIL VALIDATION
        if (validation === 'email') {
            let re = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
            setValid(re.test(String(value).toLowerCase()));
        } 

        // ... more validations can be added here
    }
    
    const resetValue = () => setValue('');

    const input = <TextField
        filled={value !== ''}
        isValid={isValid}
        onBlur={validate}
        onChange={handleChange}
        placeholder={placeholder}
        tabIndex={isVisible ? 0 : -1}
        type={type}
        value={value}
    />;
    
    return [ value, input, resetValue, isTouched, setVisible, isValid ];
}

export default useInput;