import { OPEN_MODAL, CLOSE_MODAL } from '../actions/actionTypes';

export const initialModalState = {
    isOpen: false,
    component: null
}

export const modalReducer = (state, action) => {
    switch (action.type) {
        case OPEN_MODAL:
            return {
                isOpen: true,
                component: action.payload
            }
        case CLOSE_MODAL:
            return {
                isOpen: false,
                component: null
            }
        default:
            return state;
    }
}
