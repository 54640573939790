import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import Section from '../components/Section';
import { VISION, AFFINDA } from '../constants/colours';

const ORGANISATIONS = [
    {
        logo: './images/vesparum.svg',

        logoVerticalTopAdjustment: -2,
        logoVerticalCompensationAdjustment: -3,
        logoScaleAdjustment: 1.1,

        name: 'Vesparum',
        focus: 'Capital markets expertise',
        color: VISION,
        link: '/vesparum-capital',
        departments: [
            {
                nameLines: ['Vesparum', 'Capital'],
                link: '/vesparum-capital',
                description:
                    'Proven track record in equity capital markets with >120 ASX-listed and private clients, >65 capital raisings and >95% of clients from referrals.',
            },
            {
                nameLines: ['Vesparum', 'Investment Management'],
                link: '/vesparum-investment-management',
                description:
                    'We partner with organisations where we see an opportunity for rapid value transformation through the deployment of AI products and solutions.',
            },
        ],
    },
    {
        logo: './images/affinda.png',

        // this should be done in the logo contents instead of like this
        logoVerticalTopAdjustment: -5,
        logoVerticalCompensationAdjustment: 5,
        logoScaleAdjustment: 1,

        name: 'Affinda',
        focus: 'AI and data science expertise',
        color: AFFINDA,
        link: 'https://affinda.com',
        departments: [
            {
                nameLines: ['Affinda', 'Solutions'],
                link: 'https://affinda.com',
                description:
                    'Our custom AI solutions combine world class strategy consulting with our proprietary AI technology platform to create transformative value.',
            },
            {
                nameLines: ['Affinda', 'Products'],
                link: 'https://affinda.com',
                description:
                    'Our AI software products are used in >150 countries and trusted by >500 enterprise customers with >30,000 monthly users.',
            },
        ],
    },
];

const DepartmentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding-left: ${({ isMobile, isLastDepartment }) => (!isMobile && isLastDepartment ? 8 : 0)}px;
    padding-right: ${({ isMobile, isFirstDepartment }) => (!isMobile && isFirstDepartment ? 8 : 0)}px;
    margin-top: ${({ isMobile }) => (isMobile ? 35 : 0)}px;
    width: ${({ isMobile }) => (isMobile ? '100%' : '50%')};
`;

const UnstyledLink = styled.a`
    text-decoration: none;
`;

const DepartmentName = styled.div`
    background-color: ${({ color }) => color};
    color: black;

    width: 100%;
    height: ${({ isMobile }) => (isMobile ? 150 : 85)}px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    text-align: center;

    font-size: 0.9em;

    ${({ isMobile }) =>
        isMobile &&
        css`
            font-size: 1.5em;
            font-weight: bold;
        `}

    & span {
        font-weight: 600;
    }

    &:hover {
        opacity: 0.7;
    }
`;

const DepartmentDescription = styled.p`
    text-align: center;
    font-size: 0.8em;
    min-height: 80px;
`;

const DepartmentLink = styled.a`
    color: ${({ color }) => color};
    margin-top: 20px;
    align-self: center;

    text-align: center;

    &:hover {
        opacity: 0.7;
    }
`;

const DepartmentsContainer = styled.div`
    display: flex;
    flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
    margin-top: 10px;
`;

const DesktopDepartmentLogo = styled.img`
    height: ${({ logoScaleAdjustment }) => (logoScaleAdjustment ?? 1) * 50}px;
    object-fit: contain;

    cursor: pointer;

    ${({ logoVerticalTopAdjustment }) =>
        logoVerticalTopAdjustment !== null &&
        css`
            margin-top: ${logoVerticalTopAdjustment}px;
        `}

    ${({ logoVerticalCompensationAdjustment }) =>
        logoVerticalCompensationAdjustment !== null &&
        css`
            margin-bottom: ${logoVerticalCompensationAdjustment}px;
        `}
`;

const OrganisationWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: ${({ isMobile }) => (isMobile ? '100%' : '50%')};

    ${({ isMobile }) =>
        !isMobile &&
        css`
            margin-left: 15px;
            margin-right: 15px;
        `}
`;

const MobileSectionHeader = styled.span`
    width: 100%;
    margin-top: 80px;
`;

const OrganisationsContainer = styled.div`
    display: flex;
    flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
    width: 100%;
    max-width: 1250px;
    margin-top: ${({ isMobile }) => (isMobile ? 0 : 40)}px;
`;

const ValueProposition = styled.p`
    font-size: ${({ isMobile }) => (isMobile ? '1em' : '0.85em')};
    text-align: center;
`;

const Header = styled.h1`
    font-size: 2.5em;
    text-align: center;
    ${({ isMobile }) =>
        isMobile &&
        css`
            max-width: 230px;
            margin-top: 80px;
        `}
`;

const MainContainer = styled.div`
    padding: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 300;

    ${({ isMobile }) =>
        !isMobile &&
        css`
            font-size: 1.2em;
        `}
`;

const Focus = styled.span`
    margin-top: 15px;
    font-size: 0.9em;

    ${({ color }) =>
        color &&
        css`
            color: ${color};
        `}
`;

const GroupIndicator = styled.div`
    width: 100%;
    height: 2px;
    background-color: ${({ color }) => color};
    margin-top: 15px;
`;

const MobileDepartmentLogo = styled.img`
    height: 60px;
    object-fit: contain;
    margin-top: 80px;
`;

const Landing = () => {
    const MOBILE_THRESHOLD = 768;

    const [isMobile, setMobile] = useState(window.innerWidth < MOBILE_THRESHOLD);

    // TODO: lift to a shared hook
    useEffect(() => {
        const detectScreenSize = () => {
            setMobile(window.innerWidth < MOBILE_THRESHOLD);
        };

        window.addEventListener('resize', detectScreenSize);

        return () => window.removeEventListener('resize', detectScreenSize);
    });

    return (
        <Section minHeight fullWidth gradient noOverflow name="hero">
            <MainContainer isMobile={isMobile}>
                <Header isMobile={isMobile}>Built for the AI era.</Header>

                <div style={{ maxWidth: 1000 }}>
                    <ValueProposition isMobile={isMobile}>
                        The Vesparum Group is a team of 60+ people, combining capital markets expertise with deep
                        technical expertise in AI, data science, product and engineering. Our two businesses, Vesparum
                        and Affinda, work together to create transformative value for our clients.
                    </ValueProposition>
                </div>

                {isMobile && (
                    <>
                        <MobileSectionHeader>Our businesses:</MobileSectionHeader>

                        {ORGANISATIONS.map(({ logo, name, color, focus, link }) => (
                            <>
                                <MobileDepartmentLogo
                                    src={logo}
                                    alt={name}
                                    onClick={() => window.open(link, '_blank').focus()}
                                />

                                <Focus color={color}>{focus}</Focus>
                            </>
                        ))}
                    </>
                )}

                {isMobile && <MobileSectionHeader>Business divisions:</MobileSectionHeader>}

                <OrganisationsContainer isMobile={isMobile}>
                    {ORGANISATIONS.map(
                        ({
                            logo,
                            logoVerticalTopAdjustment,
                            logoVerticalCompensationAdjustment,
                            logoScaleAdjustment,
                            name,
                            color,
                            focus,
                            departments,
                            link: organisationLink,
                        }) => (
                            <OrganisationWrapper isMobile={isMobile}>
                                {!isMobile && (
                                    <>
                                        <DesktopDepartmentLogo
                                            logoVerticalTopAdjustment={logoVerticalTopAdjustment}
                                            logoVerticalCompensationAdjustment={logoVerticalCompensationAdjustment}
                                            logoScaleAdjustment={logoScaleAdjustment}
                                            src={logo}
                                            alt={name}
                                            onClick={() => window.open(organisationLink, '_blank').focus()}
                                        />

                                        <Focus>{focus}</Focus>

                                        <GroupIndicator color={color} />
                                    </>
                                )}

                                <DepartmentsContainer isMobile={isMobile}>
                                    {departments.map(({ nameLines, description, link }, index) => (
                                        <DepartmentWrapper
                                            isLastDepartment={index + 1 === departments.length}
                                            isFirstDepartment={index === 0}
                                            isMobile={isMobile}
                                        >
                                            <UnstyledLink href={link}>
                                                <DepartmentName isMobile={isMobile} color={color}>
                                                    {nameLines.map(line => (
                                                        <span>{line}</span>
                                                    ))}
                                                </DepartmentName>
                                            </UnstyledLink>

                                            <DepartmentDescription>{description}</DepartmentDescription>

                                            <DepartmentLink color={color} href={link}>
                                                {isMobile ? `Visit ${nameLines.join(' ')} website` : 'Visit website'}
                                            </DepartmentLink>
                                        </DepartmentWrapper>
                                    ))}
                                </DepartmentsContainer>
                            </OrganisationWrapper>
                        ),
                    )}
                </OrganisationsContainer>
            </MainContainer>
        </Section>
    );
};

export default Landing;
