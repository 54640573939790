import React from 'react';
import styled from 'styled-components';
import Arrow from './Arrow';
import { DEVICES } from '../constants/breakpoints';

const Background = styled.div`
    background: ${props => (props.invert ? props.theme.graphite : 'rgba(188,189,193, 0.7)')};
    border-radius: 50%;
    height: 50px;
    left: -15px;
    position: absolute;
    top: -15px;
    transition: background 0.2s ease;
    width: 50px;
`;

const Wrapper = styled.div`
    bottom: 50px;
    cursor: pointer;
    display: none;
    position: fixed;
    opacity: ${props => (props.isVisible ? 1 : 0)};
    right: 50px;
    z-index: 999;

    &:hover {
        ${Background} {
            background: ${props => props.theme.vision};
            transition: background 0.2s ease;
        }
    }

    @media ${DEVICES.TABLET} {
        display: block;
    }
`;

const ScrollTopButton = ({ invert, isVisible = true }) => {
    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };

    return (
        <Wrapper onClick={scrollToTop} isVisible={isVisible}>
            <Arrow invert={!invert} />
            <Background invert={invert} />
        </Wrapper>
    );
};

export default ScrollTopButton;
