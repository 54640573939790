import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import { ReactComponent as DataScience } from '../../assets/images/DataScience.svg';
import { DEVICES } from '../../constants/breakpoints';
import { isIE, isEdge } from '../../utils/isIE';

const DrawGraph = keyframes`
    from {
        stroke-dashoffset: 1500;
    }
    to {
        stroke-dashoffset: 0;
    }
`;

const DataScienceGraphics = styled(DataScience)`
    left: 0;
    position: ${props => (props.isie ? 'absolute' : 'relative')};
    margin-top: -10%;
    width: 100%;
    height: auto;

    @media ${DEVICES.TABLET} {
        ${props =>
            !props.isie &&
            !props.isedge &&
            css`
            & > path {
                stroke-width: 80px;
                stroke-dasharray: 1500;
                stroke-dashoffset: 1500;

                ${props =>
                    props.animate &&
                    css`
                        animation: ${DrawGraph} 1.2s ease-in-out 0.7s forwards;
                    `}

                @media ${DEVICES.TABLET_LANDSCAPE} {
                    stroke-width: 40px;
                }
            }
        `}
    }

    @media ${DEVICES.TABLET_LANDSCAPE} {
        margin-top: -33%;
        width: 100%;

        & > path {
            stroke-width: 40px;
        }
    }

    ${props =>
        props.isie &&
        props.isedge &&
        css`
            opacity: 0;
            transform: translateY(20vh);
            transition: transform 0.6s ease-out, opacity 0.2s ease-out;

            ${props =>
                props.animate &&
                css`
                    opacity: 1;
                    transform: translateY(0);
                    transition: transform 0.6s ease-out 0.4s, opacity 0.2s ease-out 0.4s;
                `}
        `}
`;

const DataScienceAnimation = ({ inView }) => {
    return <DataScienceGraphics animate={inView ? 1 : 0} isie={isIE() ? 1 : 0} isedge={isEdge() ? 1 : 0} />;
};

export default DataScienceAnimation;
