import React, { createContext, useReducer } from 'react';

import { setSectionActive, setUpdateAllSections } from './components/Section/store/actions/actions';
import { initialSectionState, sectionReducer } from './components/Section/store/reducers/sectionReducer';

import { openModal, closeModal } from './components/Modal/store/actions/actions';
import { initialModalState, modalReducer } from './components/Modal/store/reducers/modalReducer';

export const Context = createContext(null);

const ContextState = ({ children }) => {

    const [ activeSection, dispatchSection ] = useReducer(sectionReducer, initialSectionState);
    const [ modal, dispatchModal ] = useReducer(modalReducer, initialModalState);

    return (
        <Context.Provider value={{
            // section state and action
            activeSection,
            setSectionActive: ({ name, parent, isInverted }) => dispatchSection(setSectionActive({ name, parent, isInverted })),
            setUpdateAllSections: shouldUpdate => dispatchSection(setUpdateAllSections(shouldUpdate)),
            // modal state and actions
            modal,
            openModal: component => dispatchModal(openModal(component)),
            closeModal: () => dispatchModal(closeModal())
        }}>
            {children}
        </Context.Provider>
    );
}

export default ContextState;