import React from 'react';
import {
    ModalContent,
    Title,
    ProfilePicture,
    ProfileWrapper,
    Name,
    Role,
    Content
} from './TeamStyledComponents';

const DataScienceTeamModal = () => (
    <ModalContent>
		<Title underline>Data science team</Title>
		<ProfileWrapper>
			<ProfilePicture src={`./images/ben_toner.png`} />
            <div>
			    <Name>Led by Ben Toner</Name>
			    <Role>LEAD DATA SCIENTIST</Role>
            </div>
		</ProfileWrapper>
		<Content>
			<p>Ben leads Vesparum’s data science team and research into best-in-class capital markets transactions. Ben’s background in quantum computing and deep knowledge of Australia’s equity capital markets provides a unique and valuable outsider’s perspective for Vesparum and its clients.</p>
			<p>Ben has a PhD and Master of Science in Physics from the California Institute of Technology (Caltech) and a Bachelor of Science (Honours) in Physics from the University of Melbourne, where he currently holds an honorary position as a Fellow in the School of Physics. Ben previously worked at the Dutch National Centre for Mathematics and Computer Science in Amsterdam.</p>
			<p>Ben wants to have a lasting impact on the world and sees business as a force for good. He was the Chairperson of Effective Altruism Australia, which partners with charities evaluated to be implementing the most transparent, cost-effective, and evidence-based programs for saving and improving lives.</p>
		</Content>
	</ModalContent>
);

export default DataScienceTeamModal;
