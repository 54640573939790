import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import { CloseButton } from '../StyledComponents';
import ContactForm from '../ContactForm';
import { ReactComponent as BackHomeArrow } from '../../assets/images/BackHomeArrow.svg';
import { DEVICES } from '../../constants/breakpoints';
import { isEdge } from '../../utils/isIE';

const MenuItem = styled.div`
    align-self: center;
    color: ${props => (props.active ? props.theme.vision : props.theme.vivid)};
    cursor: pointer;
    display: flex;
    flex-direction: column;
    font-style: normal;
    font-weight: normal;
    font-size: 38px;
    justify-content: center;
    line-height: 42px;
    margin: 15px 20px;
    padding: 0;
    position: relative;
    text-align: center;
    text-decoration: none;
    text-transform: lowercase;
    white-space: nowrap;
    top: 0;
    transition: color 0.2s ease;

    &:after {
        background: ${props => props.theme.vision};
        bottom: -1px;
        content: '';
        display: block;
        height: 2px;
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
        transition: width 0.2s ease;
        width: ${props => (props.active ? '100%' : 0)};

        @media ${DEVICES.TABLET} {
            background: ${props => (props.active && props.invert ? props.theme.graphite : props.theme.vivid)};
        }
    }

    &:hover {
        color: ${props => props.theme.vision};

        &:after {
            transition: width 0.2s ease;
            width: 100%;

            @media ${DEVICES.TABLET} {
                background: ${props => props.theme.vision};
            }
        }
    }

    @media ${DEVICES.TABLET} {
        letter-spacing: 0.12em;
    }

    @media ${DEVICES.TABLET} {
        color: ${props =>
            props.active && props.invert
                ? props.theme.graphite
                : props.invert
                ? props.theme.graphite
                : props.theme.vivid};
        font-size: 12px;
        font-weight: bold;
        line-height: 0;
        margin: 0 16px;
        opacity: 1;
        padding: 22px 0 10px 0;
        position: relative;
        text-transform: uppercase;

        // &:last-of-type {
        //     margin-right: 0;
        // }
    }

    ${props =>
        props.highlight &&
        css`
            border-bottom: 1px solid transparent;
            color: ${props => props.theme.vivid};
            margin: 15px 20px;
            padding: 0;
            transition: background-color 0.2s ease;

            &:hover {
                color: ${props => props.theme.vision};
            }

            @media ${DEVICES.TABLET} {
                border: 2px solid ${props => props.theme.vision};
                border-radius: 50px;
                margin: 23px 0 10px 15px;
                opacity: 1;
                padding: 22px 20px 20px 20px;
                justify-self: flex-end;

                &:hover {
                    color: ${props => props.theme.graphite};
                    background-color: ${props => props.theme.vision};

                    &:after {
                        display: none;
                    }
                }
            }
        `}

    ${props =>
        props.backhome &&
        css`
            &:hover {
                ${StyledArrow} {
                    transform: translateX(-10px);
                    transition: transform 0.2s ease-out;

                    & > path {
                        fill: ${props => props.theme.vision};
                    }
                }
            }
        `}
`;

const MenuWrapper = styled.div`
    background-color: ${props => props.theme.graphite};
    flex-direction: column;
    height: ${props => (props.menuOpen ? '100vh' : 'auto')};
    justify-content: center;
    left: 0;
    padding: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;

    /* display: none; */

    display: ${props => (props.menuOpen ? 'flex' : 'none')};

    ${MenuItem} {
        opacity: ${props => (props.menuOpen ? 1 : 0)};
        position: ${props => (props.menuOpen ? 'relative' : 'absolute')};
    }

    @media ${DEVICES.TABLET} {
        display: flex;

        background-color: transparent;
        flex-direction: row;
        height: auto;
        justify-content: flex-end;
        justify-self: flex-end;
        padding: 0 50px 15px 0;
        position: relative;
        width: auto;

        ${MenuItem} {
            opacity: 1;
            position: relative;
        }
    }
`;

const MenuButtonWrapper = styled.div`
    cursor: pointer;
    padding: 30px 20px 25px 0;
    position: relative;
    z-index: 9999;

    @media ${DEVICES.TABLET} {
        display: none;
    }
`;

const MenuButton = styled.div`
    border-bottom: 2px solid ${props => props.theme.vivid};
    color: ${props => props.theme.vivid};
    display: ${props => (props.isVisible ? 'none' : 'block')};
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.12em;
    text-transform: uppercase;

    @media ${DEVICES.TABLET} {
        border-bottom: 2px solid ${props => (props.invert ? props.theme.graphite : props.theme.vivid)};
        color: ${props => (props.invert ? props.theme.graphite : props.theme.vivid)};
    }
`;

const StyledCloseButton = styled(CloseButton)`
    &:before,
    &:after {
        background: ${props => props.theme.vivid};
    }
`;

const StyledArrow = styled(BackHomeArrow)`
    bottom: ${props => (props.isie ? 0 : '5px')};
    display: none;
    position: absolute;
    left: -30px;
    transition: transform 0.2s ease-out;

    & > path {
        fill: ${props => (props.invert ? props.theme.graphite : props.theme.vivid)};
    }

    @media ${DEVICES.TABLET} {
        display: block;
    }
`;

const Menu = ({ activeSection, closeModal, invert, openModal }) => {
    const { pathname } = useLocation();

    const [menuOpen, setMenuOpen] = useState(false);
    const [menuItems, setMenuItems] = useState([]);

    const getMenuItemsFromSections = () => {
        const sectionsDOM = document.getElementsByClassName('section');

        const menuItems = [];

        for (const sec of sectionsDOM) {
            if (sec.dataset.nav) {
                menuItems.push({
                    nav: sec.dataset.nav,
                    name: sec.dataset.name,
                });
            }
        }

        setMenuItems(menuItems);
    };

    const openMenu = () => {
        setMenuOpen(true);
    };

    const closeMenu = () => {
        setMenuOpen(false);
    };

    const scrollToSection = e => {
        const sectionName = e.target.getAttribute('name');
        const sectionDOM = document.getElementById(sectionName);
        const { offsetTop } = sectionDOM;

        window.scrollTo(0, offsetTop);
        closeMenu();
    };

    const openContactModal = () => {
        openModal(<ContactForm closeModal={closeModal} />);
    };

    useEffect(() => {
        getMenuItemsFromSections();
    }, [pathname]);

    useEffect(() => {
        const body = document.getElementsByTagName('body')[0];
        if (menuOpen) {
            body.setAttribute('style', 'overflow: hidden');
        }
        return () => body.removeAttribute('style');
    }, [menuOpen]);

    const isLanding = pathname === '/';

    const isCapitalHomepageActive = pathname === '/vesparum-capital';
    const isInvestmentManagementHomepageActive = pathname === '/vesparum-investment-management';
    const isNotAHomepage = !isCapitalHomepageActive && !isInvestmentManagementHomepageActive;

    const isCareersActive = pathname === '/careers';
    const isPublicationsActive = pathname === '/publications';
    const isMediaActive = pathname === '/media';

    const inCapitalContext = !isLanding && !isInvestmentManagementHomepageActive;

    return (
        <>
            <MenuButtonWrapper menuOpen={menuOpen} className="menu-button--wrapper">
                <MenuButton invert={invert} isVisible={menuOpen} onClick={openMenu}>
                    Menu
                </MenuButton>

                <StyledCloseButton invert={invert} isHidden={!menuOpen} onClick={closeMenu} />
            </MenuButtonWrapper>

            <MenuWrapper menuOpen={menuOpen} invert={invert} className="menu-wrapper">
                {isNotAHomepage && !isLanding && (
                    <MenuItem
                        as={Link}
                        active={0}
                        backhome={1}
                        invert={invert ? 1 : 0}
                        onClick={closeMenu}
                        // NOTE: there are no pages in the investment managment context to go "back" from, so this arrow is only used in the capital context
                        to="/vesparum-capital"
                    >
                        <StyledArrow invert={invert ? 1 : 0} isie={isEdge() ? 1 : 0} />
                        Back
                    </MenuItem>
                )}

                {menuItems.map(({ nav, name }) => {
                    const isActive = activeSection.name === name ? 1 : 0;

                    // NOTE: this is a bit of a hack to allow us to go "back" to the start of the investment management page
                    if (isInvestmentManagementHomepageActive && activeSection.name === name && name === 'first') {
                        return null;
                    }

                    return (
                        <MenuItem
                            active={isActive}
                            invert={invert ? 1 : 0}
                            key={name}
                            onClick={scrollToSection}
                            name={name}
                        >
                            {nav}
                        </MenuItem>
                    );
                })}

                {isInvestmentManagementHomepageActive && <div style={{ height: 80, width: 1 }} />}

                {inCapitalContext && (
                    <>
                        <MenuItem
                            as={Link}
                            active={isPublicationsActive ? 1 : 0}
                            invert={invert ? 1 : 0}
                            onClick={closeMenu}
                            to="/publications"
                        >
                            Publications
                        </MenuItem>
                        <MenuItem
                            as={Link}
                            active={isMediaActive ? 1 : 0}
                            invert={invert ? 1 : 0}
                            onClick={closeMenu}
                            to="/media"
                        >
                            Media
                        </MenuItem>

                        <MenuItem
                            as={Link}
                            active={isCareersActive ? 1 : 0}
                            invert={invert ? 1 : 0}
                            onClick={closeMenu}
                            to="/careers"
                        >
                            Careers
                        </MenuItem>
                    </>
                )}

                {(inCapitalContext || isLanding) && (
                    <MenuItem
                        className="main-nav-int"
                        highlight={1}
                        invert={invert ? 1 : 0}
                        onClick={openContactModal}
                        to="#"
                    >
                        Contact
                    </MenuItem>
                )}
            </MenuWrapper>
        </>
    );
};

export default Menu;
