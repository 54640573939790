import React, { useContext } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Menu from './Menu';
import { Context } from '../../ContextState';

import LogoWhite from '../../assets/images/LogoWhite.svg';
import LogoBlack from '../../assets/images/LogoBlack.svg';

import { DEVICES } from '../../constants/breakpoints';

const Wrapper = styled.div`
    background-color: rgba(31, 31, 33, 0.7);
    display: flex;
    left: 0;
    position: fixed;
    top: 0;
    transition: background-color 0.2s;
    width: 100%;
    z-index: 9999;

    @media ${DEVICES.TABLET} {
        background-color: transparent;
    }
`;

const LogoWrapper = styled(Link)`
    background-image: url(${LogoWhite});
    background-position: 20px 18px;
    background-repeat: no-repeat;
    background-size: auto 35px;
    cursor: pointer;
    flex: 1 0 auto;

    @media ${DEVICES.TABLET} {
        background-image: url('${props => (props.invert ? LogoBlack : LogoWhite)}');
        background-position: 50px 18px;
    }

    @media ${DEVICES.LAPTOP} {
        background-position: 50px 30px;
        background-size: auto 45px;
    }
`;

const TopBar = () => {
    const { activeSection, openModal, closeModal } = useContext(Context);
    const invert = activeSection.isInverted;

    const scrollTop = () => {
        window.scrollTo(0, 0);
    };

    return (
        <Wrapper invert={invert} className="top-bar">
            <LogoWrapper to="/" invert={invert ? 1 : 0} className="top-bar--logo" onClick={scrollTop} />
            <Menu activeSection={activeSection} closeModal={closeModal} invert={invert} openModal={openModal} />
        </Wrapper>
    );
};

export default TopBar;
