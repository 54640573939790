import React from 'react';
import styled, { keyframes } from 'styled-components';

const Spin = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`

const LoaderWrapper = styled.div`
    display: inline-block;
    width: 80px;
    height: 80px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 64px;
        height: 64px;
        margin: 8px;
        border: 8px solid #fff;
        border-radius: 50%;
        animation: ${Spin} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #fff transparent transparent transparent;
    }

    div:nth-child(1) {
        animation-delay: -0.45s;
    }
  
    div:nth-child(2) {
        animation-delay: -0.3s;
    }
  
    div:nth-child(3) {
        animation-delay: -0.15s;
    }
`

const Loader = () => (
    <LoaderWrapper>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </LoaderWrapper>
);

export default Loader;