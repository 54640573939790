import React, { useContext } from 'react';
import styled, { css } from 'styled-components';
import Section from '../components/Section';
import { Header, SectionContent, SectionIntro, Description } from '../components/StyledComponents';
import { Context } from '../ContextState';
import { DEVICES } from '../constants/breakpoints';
import Footer from '../components/Footer';

const StyledDescription = styled(Description)`
    color: ${props => props.theme.graphite};
`;

const StyledSectionContent = styled(SectionContent)`
    display: flex;
    flex-direction: column;
    justify-content: spaced-evenly;

    @media ${DEVICES.TABLET} {
        flex-direction: row;
    }
`;

const StyledHeader = styled(Header)`
    color: ${props => props.theme.graphite};
    @media ${DEVICES.TABLET} {
        opacity: 0;
        transform: translateY(20vh);
        transition: transform 0.6s ease-out, opacity 0.2s ease-out;

        ${props =>
    props.isActive &&
    css`
                opacity: 1;
                transform: translateY(0);
                transition: transform 0.6s ease-out 0.2s, opacity 0.2s ease-out 0.2s;
            `}
    }
`;

const Explanation = styled.div`
    font-style: normal;
    font-weight: 250;
    font-size: 14px;
    margin-bottom: 60px;
`;

const StyledArticle = styled.div`
    padding-bottom: 20px;
    padding-top: 20px;
    font-weight: 250;
    margin-bottom: 20px;
    border-bottom: 1px solid ${props => props.theme.graphite};
`;

const Date = styled.div`
    font-size: 14px;
    margin-bottom: 10px;
`;

const Title = styled.div`
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 10px;
`;

const TitleLink = styled.a`
    color: ${props => props.theme.graphite};
    text-decoration: none;
`;

const SubTitle = styled.div`
    font-size: 16px;
    margin-bottom: 10px;
`;

const Paper = ({ date, title, subtitle, link }) => {
    return (
        <>
            <StyledArticle>
                <Date>{date}</Date>
                <Title>
                    <TitleLink target="_blank" href={link}>
                        {title}
                    </TitleLink>
                </Title>
                <SubTitle>{subtitle}</SubTitle>
            </StyledArticle>
        </>
    );
};

const PublicationsWrapper = styled.div`
    color: ${props => props.theme.graphite};
    display: flex;
    flex-direction: column;
`;

const Publications = () => {
    const { activeSection } = useContext(Context);
    const inView = activeSection.name === 'publications';
    return (
        <>
            <Section invert name="publications">
                <SectionIntro>
                    <StyledHeader underline isActive>
                        Publications
                    </StyledHeader>
                </SectionIntro>
                <StyledSectionContent>
                    <PublicationsWrapper>
                        <StyledDescription>
                            Vesparum’s White Paper Series provides ASX-listed companies, investors and other market
                            participants with data-driven insights to optimise capital raising transactions during the
                            COVID-19 period.
                        </StyledDescription>
                        <Paper
                            date="15 January 2021"
                            title="Paper #4 – Inequitable treatment of retail investors in COVID-19 capital raisings"
                            subtitle="Outcomes from COVID-19 capital raisings and takeaways for directors of ASX-listed companies."
                            link="/publications/Inequitable_treatment_of_retail_investors_in_COVID-19_capital_raisings.pdf"
                        />
                        <Paper
                            date="20 July 2020"
                            title="Paper #3 – Investor trading behaviour during the COVID-19 period"
                            subtitle="Highlighting the value of retail investors for ASX-listed companies."
                            link="/publications/Investor_trading_behaviour_during_the_COVID-19_period.pdf"
                        />
                        <Paper
                            date="18 May 2020"
                            title="Paper #2 – Analysis of capital raisings in the COVID-19 period"
                            subtitle="Key observations based on the initial wave of capital raising activity."
                            link="/publications/Analysis_of_capital_raisings_in_the_COVID-19_period.pdf"
                        />
                        <Paper
                            date="24 April 2020"
                            title="Paper #1 – Raising capital during the COVID-19 crisis"
                            subtitle="What we learnt from the GFC that can be applied to the current market crisis."
                            link="/publications/Raising_capital_during_the_COVID-19_crisis.pdf"
                        />
                    </PublicationsWrapper>
                </StyledSectionContent>
            </Section>
            <Footer visible/>
        </>
    );
};
export default Publications;
