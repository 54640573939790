import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { DEVICES } from '../../constants/breakpoints';
// import isMobile from '../../utils/isMobile';
import Loader from '../Loader';

const LoaderWrapper = styled.div`
    min-height: 100vh;
    height: 100%;
`;

const VideoWrapper = styled.video`
    position: relative;
    width: 100%;
    height: auto;
    min-height: 100vh;

    @media ${DEVICES.TABLET} {
        background: ${props => props.theme.graphite};
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translateX(-50%) translateY(-50%);
        transform: translateX(-50%) translateY(-50%);
        width: 100%;
        min-height: 100vh;
    }
`;

const HeroVideo = ({ active }) => {
    const [videos, setVideo] = useState({});
    const [mobileVideo, setMobileVideo] = useState(null);
    const [isMobile, setMobile] = useState(window.innerWidth < 768);
    const [videoSource, setVideoSource] = useState(null);
    const [isActive, setActive] = useState(active);

    const video = useRef();

    useEffect(() => {
        const detectScreenSize = () => {
            setMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', detectScreenSize);

        return () => window.removeEventListener('resize', detectScreenSize);
    });

    useEffect(() => {
        setActive(isMobile || active);
    }, [active, isMobile]);

    useEffect(() => {
        const prefetchVideo = url => {
            return new Promise((resolve, reject) => {
                const xhr = new XMLHttpRequest();
                xhr.open('GET', url, true);
                xhr.setRequestHeader('Cache-Control', 'max-age=86400');
                xhr.responseType = 'blob';

                xhr.onload = () => {
                    // Onload is triggered even on 404 so we need to check the status code
                    if (xhr.status === 200) {
                        const videoBlob = xhr.response;
                        const videoSource = URL.createObjectURL(videoBlob); // IE10+
                        // Video is now downloaded
                        // and we can set it as source on the video element
                        resolve(videoSource);
                    }
                };
                xhr.onerror = () => reject(xhr.statusText);
                xhr.send();
            });
        };

        if (isMobile) {
            prefetchVideo('videos/mobile.mp4').then(res => {
                setMobileVideo(res);
            });
        } else {
            prefetchVideo('videos/part1.mp4').then(res => {
                setVideo(videos => ({ ...videos, partOne: res }));
            });

            prefetchVideo('videos/part2.mp4').then(res => {
                setVideo(videos => ({ ...videos, partTwo: res }));
            });
        }
    }, [isMobile]);

    const { partOne, partTwo } = videos;

    useEffect(() => {
        if (mobileVideo) {
            setVideoSource(mobileVideo);
        } else {
            setVideoSource(partOne);
        }
    }, [partOne, mobileVideo]);

    useEffect(() => {
        const currentVideo = video.current;

        const videoPlay = async () => {
            // currentVideo.currentTime = 0;
            try {
                await currentVideo.play();
            } catch (err) {
                console.log(err);
            }
        };

        const toggleVideo = async () => {
            if (isActive && videoSource && videoSource.length > 0) {
                currentVideo.pause();
                videoPlay();
            } else {
                currentVideo.pause();
                setVideoSource(partOne);
            }
        };

        if (isMobile && currentVideo) {
            currentVideo.pause();
            toggleVideo();
        } else if (partOne && currentVideo) {
            currentVideo.load();

            const loadNext = () => {
                currentVideo.removeEventListener('ended', loadNext);
                currentVideo.pause();
                setVideoSource(videoSource === partOne ? partTwo : partOne);
            };

            if (!isMobile) {
                currentVideo.addEventListener('ended', loadNext);
            }

            toggleVideo();

            window.addEventListener('click', videoPlay);

            return () => {
                window.removeEventListener('click', videoPlay);
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isActive, videoSource, partOne, partTwo]);

    return videoSource ? (
        <VideoWrapper
            crossOrigin="anonymous"
            autoPlay="autoplay"
            muted
            playsInline
            loop={isMobile}
            ref={video}
            src={videoSource}
            type="video/mp4"
        />
    ) : (
        <LoaderWrapper>
            <Loader />
        </LoaderWrapper>
    );
};

export default HeroVideo;
