import React from 'react';
import styled, { css } from 'styled-components';

import Section from '../../components/Section';
import { DEVICES } from '../../constants/breakpoints';

const FooterContentWrapper = styled.div`
    display: flex;
    flex-direction: column;

    @media ${DEVICES.LAPTOP} {
        flex-direction: row;
    }
`;

const Left = styled.div`
    flex: 1 1 50%;
`;

const FooterIntro = styled.div`
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 36px;

    margin-bottom: 20px;

    opacity: inherit;
    position: relative;
    z-index: 999;

    ${props =>
        props.underline &&
        css`
            margin-bottom: 60px;

            &:after {
                background: ${props => props.theme.vision};
                content: '';
                height: 8px;
                left: 0;
                position: absolute;
                top: 115%;
                width: ${props => props.underlineLength || 34}px;
            }

            @media ${DEVICES.LAPTOP} {
                margin-bottom: 60px;
            }
        `}

    @media ${DEVICES.LAPTOP} {
        font-size: 38px;
        line-height: 42px;
    }

    @media ${DEVICES.MOBILE_MAX} {
        font-size: 20px;
    }
`;

const Part = styled.div`
    color: ${props => props.theme[props.colour] || props.theme.vivid};

    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 28px;

    margin-bottom: 20px;

    @media ${DEVICES.MOBILE_MAX} {
        font-size: 14px;
        line-height: 19px;
    }
`;

const Highlight = styled(FooterIntro)`
    color: ${props => props.theme.vision};

    display: inline-block;
`;

const LinkMail = styled.a`
    color: ${props => props.theme[props.colour] || props.theme.vivid};

    margin-left: 5px;
    position: relative;
    font-weight: 400;
`;

const ContactUs = () => {
    return (
        <Section minHeightWithLimit displayName="Contact Us" name="contact">
            <FooterContentWrapper>
                <Left>
                    <FooterIntro underline>
                        Think we can help?
                        <br />
                        Please <Highlight>get in touch</Highlight>.
                    </FooterIntro>
                    <Part colour="slate">
                        Our flexible approach enables us to consider opportunities at all stages. If you would like to
                        talk to us about how we could help your business, or are interested in joining our network of
                        funding partners, please contact our investment team:&nbsp;
                        <LinkMail href="mailto:investments@vesparum.com">investments@vesparum.com</LinkMail>.
                    </Part>
                    <Part>+61 3 8582 4800</Part>
                    <Part>
                        Level 4, 180 Flinders Street
                        <br />
                        Melbourne VIC 3000
                        <br />
                        AUSTRALIA
                    </Part>
                </Left>
            </FooterContentWrapper>
        </Section>
    );
};

export default ContactUs;
