import React, { useContext, useEffect, useRef } from 'react';
// import TweenMax from 'gsap';
import { Context } from '../../ContextState';
import TeamCard from './TeamCard';
import AdvisoryTeamModal from './AdvisoryTeamModal';
import { Title, Icon, CardCopy } from './TeamStyledComponents';
import { ReactComponent as AdvisoryIcon } from '../../assets/images/icons/homepage/AdvisoryTeam.svg';

const AdvisoryTeam = () => {
    const { openModal } = useContext(Context);
    const openAdvisoryModal = () => openModal(<AdvisoryTeamModal />);

    // useEffect(() => {
    //     // wiggle();
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    const icon = useRef();

    // const wiggle = () => {
    //     if (icon.current) {
    //         TweenMax.to(icon.current, 1.5, {
    //             x: `${Math.random() * 5}%`,
    //             y: `${Math.random() * 5}%`,
    //             ease: 'sine.inOut',
    //             onComplete: () => wiggle(),
    //         });
    //     }
    // };

    return (
        <TeamCard clickThrough={openAdvisoryModal} founderLink="Founder Biography - Timothy Toner">
            <Icon ref={icon}>
                <AdvisoryIcon />
                <Title invert style={{ 'padding-top': '20px' }}>
                    Advisory
                </Title>
            </Icon>
            <CardCopy invert>
                <p>
                    Combining the full range of professional services required to optimise equity capital raising
                    outcomes, with backgrounds drawing from investment banking, management consulting, asset management
                    and law.
                </p>
                <p>
                    Previous experience at top-tier firms including: Macquarie, UBS, Morgan Stanley, Bank of America
                    Merrill Lynch, Lazard, Bain &amp; Company, L.E.K, Optiver, Allens and King &amp; Wood Mallesons.
                </p>
            </CardCopy>
        </TeamCard>
    );
};

export default AdvisoryTeam;
