import React from 'react';
import styled from 'styled-components';

const EmailAddressWrapper = styled.div`
    opacity: ${props => props.isVisible ? 1 : 0};
    pointer-events: ${props => props.isVisible ? 'initial' : 'none'};
    transition: opacity .6s ease .5s;
`

const EmailAddress = ({
    emailAddressInput,
    isVisible,
    referralType,
    setEmailVisible
}) => {
    if (isVisible) setEmailVisible(true);
    return (
        <EmailAddressWrapper isVisible={isVisible}>
            {referralType === 'referred' ? 'their' : 'my'} email address is {emailAddressInput}
        </EmailAddressWrapper>
    );
}

export default EmailAddress;
