import React from 'react';
import styled from 'styled-components';
import Section from '../components/Section';
import { Header, SubHeader } from '../components/StyledComponents';
import CtaButton from '../components/CtaButton';
import Footer from '../components/Footer';

const StyledSubHeader = styled(SubHeader)`
    font-weight: normal;
    width: 60%;
`;

const PageNotFound = () => (
    <>
        <Section minHeight visible>
            <Header hero>Oops!</Header>
            <StyledSubHeader>
                The page you were looking for doesn’t exist.
                <br />
                <CtaButton to="/" color="vision">
                    Go back home
                </CtaButton>
            </StyledSubHeader>
        </Section>
        <Footer />
    </>
);

export default PageNotFound;
