import React from 'react';
import styled from 'styled-components';

const ReferNameWrapper = styled.div`
    display: ${props => props.isHidden ? 'none' : 'inline-block'};
    opacity: ${props => props.isVisible ? 1 : 0};
    pointer-events: ${props => props.isVisible ? 'initial' : 'none'};
    transition: opacity .6s ease .5s;
    width: -webkit-fill-available;
`

const ReferName = ({
    isHidden,
    isVisible,
    referNameInput,
    setReferNameVisible
}) => {
    if (isVisible) setReferNameVisible(true)
    return (
        <ReferNameWrapper isVisible={isVisible} isHidden={isHidden}>
            {referNameInput}
      </ReferNameWrapper>
    );
};

export default ReferName;
