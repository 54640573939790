import React from 'react';
import styled from 'styled-components';
import Section from '../components/Section';
import Footer from '../components/Footer';
import { Header } from '../components/StyledComponents';

const Link = styled.a`
    color: ${props => props.theme.vivid};
`;

const PrivacyPolicy = () => (
    <>
        <Section>
            <Header large>Privacy Policy</Header>
            <p>
                This Privacy Policy applies to how Vesparum Capital Pty Ltd ACN 160 118 141 AFSL 432092 (Vesparum
                Capital, we and us) uses your personal information. We recognise the importance of your privacy and
                understand your concerns about the security of your personal information. We are committed to protecting
                any personal information about you that we hold in accordance with privacy legislation, including the
                National Privacy Principles within the Privacy Act 1988 (Cth).
            </p>

            <h2>Personal information</h2>
            <p>
                Personal information is information or opinion that personally identifies you, such as your name, email
                address and contact details.
            </p>

            <h2>Collecting personal information</h2>
            <p>
                We may from time to time collect personal information about you from a variety of sources including: (a)
                directly from you, such as through a form on this website; (b) from third parties such as our related
                companies, partners, clients, vendors or your representatives; or (c) from publicly available
                information.
            </p>

            <h2>Why we collect personal information and how we use it</h2>
            <p>
                We collect personal information about you to provide you with our services and for related purposes such
                as: (a) communicating with you, including responding to any questions; (b) helping us to identify and
                inform you about our services that may be of interest to you; (c) do other things necessary and
                incidental to our provisions of services to you; and (d) for general business management, including
                enhancement of our services, internal accounting and administration purposes. If you do not provide us
                with your personal information, we may not be able to provide you with our services. If we no longer
                need to use your personal information, we will take reasonable steps to destroy or permanently
                de-identify it.
            </p>

            <h2>Disclosure of personal information</h2>
            <p>
                We may share your personal information: (a) with any company related to us; (b) with certain service
                providers such as providers of the electronic systems we use to collect and store your personal
                information and other service providers we use to help us run aspects of our business efficiently; and
                (c) if we are required or authorised by law to do so. Occasionally, we might use your personal
                information for other purposes or share your personal information with another organisation because
                you’d expect us to or we have told you we will or we believe it is necessary to do so to: (a) provide
                you with a service which you have requested; (b) prevent or help detect fraud or serious credit
                infringements; or (c) protect our interests; for example, disclosure to a court in the event of legal
                action to which we are a party. When we share information with these other organisations, we do so in
                accordance with this Privacy Policy. These organisations are covered by privacy rules that prevent them
                from keeping or using this information for any other purpose. Other than that, we do not generally give
                your personal information to anyone else unless you have given us your consent.
            </p>

            <h2>Marketing</h2>
            <p>
                We’d like to keep you up-to-date with our special offers, products and services, unless you’ve asked us
                not to. You can choose not to receive marketing information from us by contacting us at
                advisory@vesparum.com. You agree that this consent waives any requirement for us to include this
                unsubscribe message in every marketing communication you receive from us.
            </p>

            <h2>Security of your personal information</h2>
            <p>
                We take reasonable steps to keep your personal information secure. Only authorised users can access your
                personal information. Unfortunately, we can’t give you an absolute guarantee that your information is
                always secure, as no data sent over the internet is 100% secure. While we will do our best, we can’t be
                held responsible for events arising from unauthorised access to your personal information.
            </p>

            <h2>Changes to your personal information</h2>
            <p>
                You can change or update any of your personal information at any time by contacting us below. If you
                want us to delete personal information we hold about you, please let us know and we will do our best to
                delete it unless we need to keep it for any of the purposes identified in this Privacy Policy or for
                legal reasons. We will try to keep all of your personal information current and up to date, but it’s
                your responsibility to ensure the correctness of the personal information and identify any errors or
                changes to it.
            </p>

            <h2>Cookies</h2>
            <p>
                When you visit our website, our server may attach a small data file known as a “cookie” to your hard
                drive. Cookies are pieces of information that may be transferred to your computer’s memory when you
                visit a website for record keeping purposes. Most web browsers are set to accept cookies. If you do not
                wish to receive any cookies you may set your Internet browser to refuse cookies or to notify you when a
                cookie is sent. We may use cookies to provide us with aggregate information on how people use our
                website and to help us know what they find interesting and useful on our website. No personal
                information is collected on these occasions.
            </p>

            <h2>Linked third party websites</h2>
            <p>
                From time to time our website may provide links to third party websites or third party websites may link
                to our website. These linked sites are not under our control and we are not responsible for the conduct
                of companies linked to our website. Before disclosing your personal information to any other website,
                you should examine the terms and conditions for using these websites and the privacy policies
                applicable.
            </p>

            <h2>Changes to our Privacy Policy</h2>
            <p>
                From time to time it may be necessary for us to review and revise our Privacy Policy. We reserve the
                right to change our Privacy Policy at any time and to notify you by publishing an updated version of the
                policy on our website at www.vesparum.com.
            </p>

            <h2>Contact details</h2>
            <p>
                If you have any complaints in relation to your privacy or questions about privacy or the way in which we
                handle your personal information, you may contact us in the following way:
            </p>

            <p>Telephone: +61 3 8582 4800</p>

            <p>
                Email: <Link href="mailto:advisory@vesparum.com">advisory@vesparum.com</Link>
            </p>

            <p>Street address: Level 4, 180 Flinders St, Melbourne VIC 3000</p>

            <p>Postal address: GPO Box 265, Melbourne VIC 3001</p>
        </Section>
        <Footer visible />
    </>
);

export default PrivacyPolicy;
