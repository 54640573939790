import React, { Component, createRef, useContext } from 'react';
import styled from 'styled-components';
import { TweenMax } from 'gsap';
import Section from '../../components/Section';
import AnimatedCircles from '../../components/Animations/AnimatedCircles';
import { Context } from '../../ContextState';

import { Description, Header } from '../../components/StyledComponents';
import CtaButton from '../../components/CtaButton';

import { DEVICES } from '../../constants/breakpoints';

const AnimationGroup = styled.div`
    position: absolute;
    top: 55%;
    transform: translateY(-50%);
    width: 80%;
    z-index: 4;

    @media ${DEVICES.LAPTOP} {
        width: 100%;
    }
`;

const Break = styled.div`
    display: none;
    @media ${DEVICES.TABLET} {
        display: block;
    }
`;

class Content extends Component {
    constructor(props) {
        super(props);

        this.body = document.getElementsByTagName('body')[0];

        this.headerWWU = createRef();
        this.descriptionWWU = createRef();
        this.ctaWWU = createRef();
    }

    componentDidMount() {
        // locks body from scrolling until the animation finishes
        this.body.setAttribute('style', 'overflow: hidden');

        const headerWWU = this.headerWWU.current;
        const descWWU = this.descriptionWWU.current;
        const ctaWWU = this.ctaWWU.current;

        TweenMax.fromTo(
            headerWWU,
            0.6,
            { y: '-10vh', opacity: 0 },
            { y: '0', opacity: 1, delay: 0.8, ease: 'sine.out' },
        );
        TweenMax.fromTo(descWWU, 0.8, { y: '-5vh', opacity: 0 }, { y: '0', opacity: 1, delay: 1, ease: 'sine.out' });
        TweenMax.fromTo(
            ctaWWU,
            0.8,
            { y: '-2vh', opacity: 0 },
            {
                y: '0',
                opacity: 1,
                delay: 1.2,
                ease: 'sine.out',
                onComplete: () => {
                    this.body.removeAttribute('style');
                },
            },
        );
    }

    render() {
        return (
            <AnimationGroup>
                <Header ref={this.headerWWU}>
                    More Silicon Valley <Break />
                    than Wall Street
                </Header>
                <Description hero ref={this.descriptionWWU} width="50%">
                    Vesparum is a financial technology company full of smart, purposeful people. Our data science
                    approach puts us at the forefront of equity capital markets innovation globally.
                </Description>
                <CtaButton color="vision" hash="what-we-value" ref={this.ctaWWU}>
                    What we value
                </CtaButton>
            </AnimationGroup>
        );
    }
}

const WorkWithUs = () => {
    const { activeSection } = useContext(Context);
    const inView = activeSection.name === 'work-with-us';
    return (
        <Section minHeight name="work-with-us">
            <AnimatedCircles active={inView}>
                <Content />
            </AnimatedCircles>
        </Section>
    );
};

export default WorkWithUs;
