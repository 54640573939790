import { SET_SECTION_ACTIVE, UPDATE_ALL_SECTIONS } from '../actions/actionTypes';

export const initialSectionState = {
    isInverted: false,
    name: null,
    updateAll: false
}

export const sectionReducer = (state, action) => {
    switch (action.type) {
        case SET_SECTION_ACTIVE:
            const { name, isInverted } = action.payload;
            return {
                name,
                isInverted,
                updateAll: false
            };
        case UPDATE_ALL_SECTIONS:
            return {
                ...state,
                updateAll: action.payload
            }
        default:
            return state;
    }
}