import React from 'react';
import styled, { css } from 'styled-components';
import { DEVICES } from '../../constants/breakpoints';

import Dropdown from './inputs/Dropdown';
import CompanyName from './inputs/CompanyName';
import EmailAddress from './inputs/EmailAddress';
import ReferName from './inputs/ReferName';
import Comment from './inputs/Comment';
import ThankYou from './ThankYou';
import { Header } from '../StyledComponents';

const Title = styled(Header)`
    color: ${props => props.theme.graphite};
    display: inline-block;
    font-size: 36px;
    font-style: normal;
    font-weight: bold;
    @media ${DEVICES.LAPTOP} {
        font-size: 36px;
    }
`;

const Content = styled.div`
    color: ${props => props.theme.graphite};
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 44px;
    overflow: hidden;

    @media ${DEVICES.TABLET_LANDSCAPE} {
        font-size: 38px;
        line-height: 56px;
    }
`;

export const Button = styled.button`
    background-color: ${props => (props.disabled ? props.theme.slate : props.theme.vision)};
    border: 2px solid ${props => (props.disabled ? props.theme.slate : props.theme.vision)};
    border-radius: 50px;
    color: ${props => props.theme.graphite};
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 14px;
    letter-spacing: 0.06em;
    opacity: ${props => (props.isVisible ? 1 : 0)};
    padding: 15px 30px;
    text-transform: uppercase;
    transition: background-color 0.2s ease, opacity 0.2s ease;

    ${props =>
        !props.disabled &&
        css`
            &:hover {
                background-color: transparent;
            }
        `}

    &:focus {
        outline: 0;
    }
`;

const ErrorMessage = styled.div`
    color: ${props => props.theme.error};
    opacity: ${props => (props.isVisible ? 1 : 0)};
    transition: opacity 0.2s ease;
    padding: 10px 0 20px 0;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;

    @media ${DEVICES.TABLET_LANDSCAPE} {
        font-size: 24px;
        line-height: 38px;
    }
`;

const FullNameWrapper = styled.div`
    display: flex;
    flex-direction: row;
    white-space: nowrap;
`;

const FullNameInputWrapper = styled.div`
    display: inline-block;
    margin-left: 10px;
    width: -webkit-fill-available;
`;

const ReferWrapper = styled.div`
    display: flex;
    flex-direction: column;

    ${DEVICES.TABLET_LANDSCAPE} {
        flex-direction: row;
    }
`;

const ContactForm = ({
    closeContactModal,
    handleDropdown,

    companyName,
    companyNameInput,
    setCompanyVisible,

    emailAddress,
    emailAddressInput,
    isEmailValid,
    setEmailVisible,

    fullName,
    fullNameInput,
    setFullNameVisible,

    referName,
    referNameTouched,
    referNameInput,
    setReferNameVisible,

    referralType,

    commentInput,
    setCommentVisible,

    submitForm,
    isSubmitted,
}) => {
    if (isSubmitted) {
        return <ThankYou closeContactModal={closeContactModal} referralType={referralType} referName={referName} />;
    }
    // sets the first input field visible
    // and then they progressively appear as the form is being populated
    setFullNameVisible(true);
    return (
        <>
            <Title underline>Contact</Title>
            <Content>
                <FullNameWrapper>
                    My name is <FullNameInputWrapper>{fullNameInput}</FullNameInputWrapper>
                </FullNameWrapper>

                <ReferWrapper>
                    <Dropdown handleDropdown={handleDropdown} isVisible={fullName} referralType={referralType} />
                    <ReferName
                        isHidden={referralType === 'get-in-touch'}
                        isVisible={fullName}
                        referNameInput={referNameInput}
                        setReferNameVisible={setReferNameVisible}
                    />
                </ReferWrapper>

                <CompanyName
                    companyNameInput={companyNameInput}
                    isVisible={referNameTouched || referralType === 'get-in-touch'}
                    referralType={referralType}
                    setCompanyVisible={setCompanyVisible}
                />

                <EmailAddress
                    emailAddressInput={emailAddressInput}
                    isVisible={companyName}
                    referralType={referralType}
                    setEmailVisible={setEmailVisible}
                />

                <Comment commentInput={commentInput} isVisible={emailAddress} setCommentVisible={setCommentVisible} />
            </Content>

            <ErrorMessage isVisible={!isEmailValid}>Please enter a valid email address before continuing.</ErrorMessage>

            <Button isVisible={emailAddress} disabled={!isEmailValid} onClick={submitForm}>
                Submit
            </Button>
        </>
    );
};

export default ContactForm;
