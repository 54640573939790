import React, { useContext } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Context } from '../ContextState';
import LogoWhite from '../assets/images/LogoWhite.svg';
import Section from './Section';
import { DEVICES } from '../constants/breakpoints';
import ContactForm from './ContactForm';

const FooterContentWrapper = styled.div`
    border-bottom: 1px solid ${props => props.theme.vivid};
    display: flex;
    flex-direction: column;

    @media ${DEVICES.LAPTOP} {
        flex-direction: row;
    }
`;

const Left = styled.div`
    flex: 1 1 50%;
`;

const Right = styled.div`
    align-items: flex-start;
    flex: 1 1 50%;
    display: flex;
    flex-direction: column;

    @media ${DEVICES.LAPTOP} {
        align-items: flex-end;
    }
`;

const Logo = styled.div`
    background-image: url('${LogoWhite}');
    background-size: 140px;
    height: 29px;
    margin-bottom: 20px;
    width: 140px;

    @media ${DEVICES.LAPTOP} {
        background-size: 208px;
        height: 43px;
        width: 208px;
    }
`;

const FooterLink = styled(Link)`
    display: none;

    @media ${DEVICES.LAPTOP} {
        border-bottom: 1px solid transparent;
        color: ${props => props.theme.vivid};
        display: block;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        letter-spacing: 0.12em;
        margin: 20px 0;
        position: relative;
        text-decoration: none;
        text-transform: uppercase;

        &:after {
            background: ${props => props.theme.vision};
            bottom: -1px;
            content: '';
            display: block;
            height: 1px;
            left: 50%;
            position: absolute;
            transform: translateX(-50%);
            transition: width 0.2s ease;
            width: 0;
        }

        &:hover {
            color: ${props => props.theme.vision};

            &:after {
                transition: width 0.2s ease;
                width: 100%;
            }
        }
    }
`;

const FooterIntro = styled.div`
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 36px;
    margin-bottom: 40px;

    @media ${DEVICES.LAPTOP} {
        font-size: 38px;
        line-height: 42px;
    }
`;

const PhoneNumber = styled.div`
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 20px;
`;

const Address = styled.div`
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 80px;
`;

const LinkContact = styled.span`
    color: ${props => props.theme.vision};
    cursor: pointer;
    display: inline-block;
    position: relative;

    &:after {
        background-color: ${props => props.theme.vision};
        content: '';
        bottom: 2px;
        left: 23px;
        height: 2px;
        position: absolute;
        transition: transform 0.2s ease;
        width: 0;
    }

    &:hover {
        &:after {
            transition: width 0.2s ease;
            width: 89%;
        }
    }
`;

const BottomMenuWrapper = styled.div`
    margin-top: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media ${DEVICES.TABLET} {
        flex-direction: row;
    }
`;

const Copyright = styled.div`
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 26px;
    letter-spacing: 0.12em;
    text-transform: uppercase;
`;

const PrivacyLink = styled(Link)`
    color: ${props => props.theme.vivid};
    margin-left: 5px;
    position: relative;
    text-decoration: none;

    &:first-child {
        margin-left: 0;
    }

    &:after {
        background: ${props => props.theme.vision};
        bottom: -1px;
        content: '';
        display: block;
        height: 1px;
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
        transition: width 0.2s ease;
        width: 0;
    }

    &:hover {
        color: ${props => props.theme.vision};

        &:after {
            transition: width 0.2s ease;
            width: 100%;
        }
    }

    @media ${DEVICES.LAPTOP} {
        margin-left: 20px;
    }
`;

const PrivacyMenu = styled.div`
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.12em;
    text-transform: uppercase;
    margin-top: 10px;

    @media ${DEVICES.TABLET} {
        margin-top: 0;
    }
`;

const Footer = ({ visible }) => {
    const { closeModal, openModal } = useContext(Context);

    const openContactModal = () => {
        openModal(<ContactForm closeModal={closeModal} />);
    };

    const scrollTop = () => {
        window.scrollTo(0, 0);
    };

    return (
        <Section name="footer" visible={visible}>
            <FooterContentWrapper>
                <Left>
                    <FooterIntro>
                        Think we can help?
                        <br />
                        Please <LinkContact onClick={openContactModal}>get in touch</LinkContact>.
                    </FooterIntro>
                    <PhoneNumber>
                        Telephone:
                        <br />
                        +61 3 8582 4800
                    </PhoneNumber>
                    <Address>
                        Level 4, 180 Flinders St
                        <br />
                        Melbourne VIC 3000
                        <br />
                        AUSTRALIA
                    </Address>
                </Left>

                <Right>
                    {/* <Logo />
                    <FooterLink to="/careers" onClick={scrollTop}>
                        Careers
                    </FooterLink>
                    <FooterLink to="" onClick={openContactModal}>
                        Contact
                    </FooterLink> */}
                </Right>
            </FooterContentWrapper>

            <BottomMenuWrapper>
                <Copyright>
                    &copy; 2021 Vesparum Capital
                    <br />
                    ABN: 18 160 118 141
                    <br />
                    AFSL: 432092
                </Copyright>
                <div>
                    <a href="https://www.linkedin.com/company/vesparum-capital/" target="_blank">
                        <img
                            src="./images/socialmedia/linkedin.svg"
                            alt="Linkedin"
                            height="40px"
                            style={{ marginRight: '10px' }}
                        />
                    </a>
                    <a href="https://twitter.com/VesparumCapital" target="_blank">
                        <img src="./images/socialmedia/Twitter_Logo_WhiteOnImage.svg" alt="Twitter" height="40px" />
                    </a>
                </div>
                <div>
                    <PrivacyMenu>
                        {/* <PrivacyLink to={`/press`}>Press</PrivacyLink> */}
                        <PrivacyLink to="/terms" onClick={scrollTop}>
                            Terms
                        </PrivacyLink>
                        <PrivacyLink to="/privacy" onClick={scrollTop}>
                            Privacy
                        </PrivacyLink>
                    </PrivacyMenu>
                </div>
            </BottomMenuWrapper>
        </Section>
    );
};

export default Footer;
