import React, { useContext } from 'react';
import styled, { css } from 'styled-components';
import Section from '../../components/Section';
import { Context } from '../../ContextState';
import { DEVICES } from '../../constants/breakpoints';
import {
    Description,
    Header,
    SubHeader,
    SectionContent,
    SectionContentWrapper,
    SectionIntro,
} from '../../components/StyledComponents';
import { ReactComponent as Integrity } from '../../assets/images/icons/careers/Integrity.svg';
import { ReactComponent as Resourcefullness } from '../../assets/images/icons/careers/Resourcefulness.svg';
import { ReactComponent as Trusted } from '../../assets/images/icons/careers/Trusted.svg';

const StatementWrapper = styled.div`
    display: flex;

    @media ${DEVICES.TABLET} {
        opacity: 0;
        transform: translateY(20vh);
        transition: transform 0.6s ease-out, opacity 0.2s ease-out;

        /* Animate elements when section scrolls into view */
        ${props =>
            props.isActive &&
            css`
            opacity: 1;
            transform: translateY(0);
            transition: transform .6s ease-out, opacity .2s ease-out;

            ${props =>
                props.order === 1 &&
                css`
                    transition-delay: 0.4s;
                `}

            ${props =>
                props.order === 2 &&
                css`
                    transition-delay: 0.6s;
                `}

            ${props =>
                props.order === 3 &&
                css`
                    transition-delay: 0.8s;
                `}
        `}
    }
`;

const StatementIcon = styled.div`
    margin-right: 10%;
`;

const StatementCopy = styled.div`
    flex: 2;
`;

const StyledIntro = styled(SectionIntro)`
    flex: 4;
`;

const StyledContent = styled(SectionContent)`
    flex: 5;
`;

const StyledHeader = styled(Header)`
    @media ${DEVICES.TABLET} {
        opacity: 0;
        transform: translateY(20vh);
        transition: transform 0.6s ease-out, opacity 0.2s ease-out;

        ${props =>
            props.isActive &&
            css`
                opacity: 1;
                transform: translateY(0);
                transition: transform 0.6s ease-out 0.2s, opacity 0.2s ease-out 0.2s;
            `}
    }
`;

const WhatWeValue = () => {
    const { activeSection } = useContext(Context);
    const inView = activeSection.name === 'what-we-value';

    return (
        <Section invert name="what-we-value" displayName="what we value">
            <SectionContentWrapper>
                <StyledIntro>
                    <StyledHeader invert underline isActive={inView}>
                        What we value
                    </StyledHeader>
                </StyledIntro>

                <StyledContent>
                    <StatementWrapper isActive={inView} order={1}>
                        <StatementIcon>
                            <Integrity />
                        </StatementIcon>
                        <StatementCopy>
                            <SubHeader invert>Integrity</SubHeader>
                            <Description colour="graphite">
                                We act as trusted advisor to our clients. Strong moral and ethical principles are
                                paramount.
                            </Description>
                        </StatementCopy>
                    </StatementWrapper>

                    <StatementWrapper isActive={inView} order={2}>
                        <StatementIcon>
                            <Resourcefullness />
                        </StatementIcon>
                        <StatementCopy>
                            <SubHeader invert>Humility</SubHeader>
                            <Description colour="graphite">
                                Our success is measured by the success of our clients. Humility is not thinking less of
                                yourself, but thinking of yourself less.
                            </Description>
                        </StatementCopy>
                    </StatementWrapper>

                    <StatementWrapper isActive={inView} order={3}>
                        <StatementIcon>
                            <Trusted />
                        </StatementIcon>
                        <StatementCopy>
                            <SubHeader invert>Empathy</SubHeader>
                            <Description colour="graphite">
                                The ability to think from the perspective of our clients and their shareholders is
                                fundamental to everything we do.
                            </Description>
                        </StatementCopy>
                    </StatementWrapper>
                </StyledContent>
            </SectionContentWrapper>
        </Section>
    );
};

export default WhatWeValue;
