import React from 'react';
import {
    ModalContent,
    Title,
    ProfilePicture,
    ProfileWrapper,
    Name,
    NameWrapper,
    Role,
    Content,
} from './TeamStyledComponents';

const AdvisoryTeamModal = () => (
    <ModalContent>
        <Title underline>Advisory team</Title>
        <ProfileWrapper>
            <ProfilePicture src="./images/tim_toner_sq.png" />
            <NameWrapper>
                <Name>Led by Timothy Toner</Name>
                <Role>FOUNDER AND MANAGING DIRECTOR</Role>
                <a href="https://www.linkedin.com/in/timothytoner/" target="_blank">
                    <img
                        src="./images/socialmedia/LI-In-Bug-no-tm.png"
                        alt="LinkedIn"
                        style={{ width: '25px', 'padding-top': '10px' }}
                    />
                </a>
            </NameWrapper>
        </ProfileWrapper>
        <Content>
            <p>
                Timothy founded Vesparum to bring data science to Australia's equity capital markets. Vesparum provides
                truly independent advice to help companies and their board and management teams optimise capital raising
                transactions. Timothy’s experience both as a founder/operator and an advisor provides him with a unique
                ability to deliver both insightful and actionable advice that solves for a client’s needs, whether
                short, medium or long-term.
            </p>
            <p>
                Timothy draws on more than 15 years of capital markets experience. Since founding Vesparum in 2012, he
                has overseen a broad range of public and private client engagements. Previously, Timothy spent several
                years at global investment bank, Macquarie Capital, and independent financial advisory and asset
                management firm, Lazard. Timothy has worked across all aspects of the capital markets, with a focus on
                principal investments, equity capital markets and M&A. He holds Bachelor of Commerce and Master of
                Commerce degrees from the University of Melbourne.
            </p>
            <p>Timothy’s challenge is to run a successful business while mindfully engaging with his young family.</p>
        </Content>
    </ModalContent>
);

export default AdvisoryTeamModal;
