import React from 'react';
import Section from '../components/Section';
import Footer from '../components/Footer';
import { Header } from '../components/StyledComponents';

const TermsOfUse = () => (
    <>
        <Section>
            <Header large>Terms of Use</Header>
            <p>
                This website is owned and operated by Vesparum Capital Pty Ltd ACN 160 118 141 AFSL 432092. All
                references to “Vesparum Capital”, “we”, “us” or “our” in this policy are references to Vesparum Capital
                Pty Ltd. Your use of this website is subject to the following terms. By accessing this website, the
                pages contained on it, and the products, services, information, tools and material contained or
                described herein, you acknowledge your agreement with, and understanding of, the following terms of use.
                Please check these terms regularly to ensure that you are aware of any changes. Your continued use of
                the website following the posting of changes to these terms will mean you accept any changes made to the
                website.
            </p>
            <h2>Australian Residents</h2>
            <p>
                The products and services described in this website are not available in countries or to residents of
                countries where it is unlawful to provide the information contained in this website or where it is
                unlawful to offer the products and services described in this website. Any financial service included on
                this website is offered only to Australian residents and may only be subscribed for by an Australian
                resident or an overseas person where it is lawful to make that offer, invitation or subscription. The
                information provided in this website has been provided in accordance with Australian laws only and may
                not satisfy the laws of other countries.
            </p>
            <h2>Advice warning</h2>
            <p>
                To the extent that any information on this website constitutes advice, the advice is general advice
                only. This website contains general information about financial services and that information does not
                constitute an offer or inducement to enter into a legally binding contract, and does not form part of
                the terms and conditions for those financial services. The advice does not take into account your
                investment objectives, financial situation or needs. Before making any investment decision you should
                consider whether the advice is appropriate in light of your objectives, financial situation and needs or
                consult a professional adviser for specific advice. Please note that Vesparum Capital offers financial
                services to wholesale clients only.
            </p>
            <h2>Copyright</h2>
            <p>
                Unless expressly stated to the contrary, copyright in the material, information, trademarks and data
                contained in this website is owned or licensed by Vesparum Capital. Nothing on the website shall be
                construed as granting any license or right to use any image, trademark, service mark or logo. It is
                intended that persons will use this information for their own personal use and such information may not
                otherwise be produced or distributed or transmitted to any other person. Downloading, copying or
                printing individual pages and/or parts of this website will not transfer title to any software or
                material on the website to you. Any reproduction, re-publication or distribution of this material
                requires the express consent of Vesparum Capital.
            </p>
            <h2>Material on this site</h2>
            <p>
                Vesparum Capital provides all information or material on this website in good faith. Vesparum Capital
                makes no representations nor gives any warranties as to its accuracy, timeliness, completeness or
                suitability. Vesparum Capital and its directors, employees and associates hereby expressly disclaim to
                the fullest extent permitted by law any responsibility arising in any way for errors in or omissions
                from the information or material on this website and do not accept any loss or damage as a result of any
                person relying on any information on this website including any information provided by any third
                parties or for any inability to access this site at any time. This disclaimer is subject to any contrary
                provisions contained in the Competition and Consumer Act 2010. The use of this website does not create
                any relationship between the user and Vesparum Capital.
            </p>
            <h2>Network integrity</h2>
            <p>
                While Vesparum Capital has taken reasonable steps to ensure the integrity of our computer networks, we
                are unable to guarantee files and attachments are free from contamination. Before opening any
                attachments or emails, or downloading files from this website, visitors to the Vesparum Capital website
                should ensure they have precautions in place to protect against viral infection.
            </p>
            <h2>Linked third party websites</h2>
            <p>
                The Vesparum Capital website may contain links to other websites, however, we do not endorse, review,
                approve or accept any responsibility in relation to the operators of linked third party sites or the
                information and material on such sites, nor the products or services offered. To the extent permitted by
                law, Vesparum Capital and its directors, employees and associates disclaim all responsibility for any
                loss or damage which may be suffered by any person directly or indirectly through any person’s dealings
                with them or by use of the website whether that loss or damage is caused by any fault or negligence on
                the part of Vesparum Capital, its directors, employees and associates.
            </p>
            <h2>Performance guarantee</h2>
            <p>
                Vesparum Capital does not guarantee the performance of any financial products mentioned on this website
                and makes no representation as to the success or otherwise of any financial services provided. All
                financial services activities involve risk, and past performance is not an indicator of future results.
            </p>
            <h2>Disclosure of interests</h2>
            <p>
                Vesparum Capital does not have any relationships or associations which might influence us in providing
                you with our services.
            </p>
            <h2>Indemnity</h2>
            <p>
                Other than any responsibilities implied by law which cannot be excluded, you agree to indemnify and hold
                Vesparum Capital and its directors, employees, and associates harmless from any claim or demand,
                including reasonable legal fees, whatsoever arising out of or referable to material on this website or
                to any linked third party material, whether in contract, tort including negligence, statute or
                otherwise. You expressly understand and agree that your use of the service is at your sole risk and that
                this website is provided on an “as is” and “as available” basis.
            </p>
            <h2>Jurisdiction</h2>
            <p>
                All information and services contained within this website are governed by and are to be construed
                according to the laws applicable in the State of Victoria and the Commonwealth of Australia.
            </p>
        </Section>
        <Footer visible />
    </>
);

export default TermsOfUse;
