import React, { useState } from 'react';
import Contact from './ContactForm';
import useInput from './utils/useInput';

const ContactFormContainer = ({ closeModal }) => {
    const [fullName, fullNameInput, resetFullName, fullNameTouched, setFullNameVisible] = useInput({
        type: 'text',
        placeholder: 'Full Name',
    });
    const [referName, referNameInput, resetReferName, referNameTouched, setReferNameVisible] = useInput({
        type: 'text',
        placeholder: 'Full Name',
    });
    const [companyName, companyNameInput, resetCompanyName, companyNameTouched, setCompanyVisible] = useInput({
        type: 'text',
        placeholder: 'Company Name',
    });
    const [
        emailAddress,
        emailAddressInput,
        resetEmailAddress,
        emailAddressTouched,
        setEmailVisible,
        isEmailValid,
    ] = useInput({ type: 'text', validation: 'email', placeholder: 'email@address.com' });
    const [comment, commentInput, resetComment, commentTouched, setCommentVisible] = useInput({
        type: 'text',
        placeholder: `Anything you'd like to add`,
    });

    const [referralType, setReferralType] = useState('referred');
    const [isSubmitted, setSubmitted] = useState(false);

    const closeContactModal = () => {
        resetValues();
        setSubmitted(false);
        closeModal();
    };

    const resetValues = () => {
        resetFullName();
        resetReferName();
        resetCompanyName();
        resetEmailAddress();
        resetComment();
    };

    const handleDropdown = e => setReferralType(e.target.value);

    // we were meant to use AWS to host the website and handle the contact from
    // https://aws.amazon.com/blogs/architecture/create-dynamic-contact-forms-for-s3-static-websites-using-aws-lambda-amazon-api-gateway-and-amazon-ses/
    // the API end point URL needs to replaced
    const submitForm = () => {
        if (isEmailValid) {
            setSubmitted(true);
            const data = {
                fullName,
                referName,
                companyName,
                emailAddress,
                comment,
                referralType,
            };

            fetch('https://cb5ir676te.execute-api.ap-southeast-2.amazonaws.com/live/contact', {
                method: 'POST',
                headers: new Headers(),
                body: JSON.stringify(data),
            })
                .then(res => res.json())
                .then(data => console.log(data))
                .catch(err => console.log(err));
        }
    };

    return (
        <Contact
            closeContactModal={closeContactModal}
            handleDropdown={handleDropdown}
            companyName={companyNameTouched}
            companyNameInput={companyNameInput}
            setCompanyVisible={setCompanyVisible}
            emailAddress={emailAddressTouched}
            emailAddressInput={emailAddressInput}
            isEmailValid={isEmailValid}
            setEmailVisible={setEmailVisible}
            fullName={fullNameTouched}
            fullNameInput={fullNameInput}
            setFullNameVisible={setFullNameVisible}
            referName={referName}
            referNameTouched={referNameTouched}
            referNameInput={referNameInput}
            referralType={referralType}
            setReferNameVisible={setReferNameVisible}
            comment={commentTouched}
            commentInput={commentInput}
            setCommentVisible={setCommentVisible}
            submitForm={submitForm}
            isSubmitted={isSubmitted}
        />
    );
};

export default ContactFormContainer;
