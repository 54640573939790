import React, { useContext, useRef, useEffect } from 'react';
import { Context } from '../../ContextState';
import Modal from './Modal';

const ModalContainer = () => {
    const ref = useRef();
    const { modal, closeModal } = useContext(Context);
    const { isOpen, component } = modal;

    useEffect(() => {
        const handleClickAway = e => {
            if (ref.current && !ref.current.contains(e.target)) { closeModal(); }
        }
        window.addEventListener('mousedown', handleClickAway);
        return () => window.removeEventListener('mousedown', handleClickAway);
    }, [ ref, closeModal ])

    useEffect(() => {
        const body = document.getElementsByTagName('body')[0];
        if (isOpen) { body.setAttribute('style', 'overflow: hidden');}
        return () => body.removeAttribute('style');
    }, [ isOpen ])

    
    return isOpen && (
        <Modal
            closeModal={closeModal}
            ref={ref}
        >
            {component}
        </Modal>
    )
}

export default ModalContainer;