import React from 'react';
import { Header } from '../components/StyledComponents';
import Section from '../components/Section';

const Press = () => (
    <Section>
        <Header large>Press</Header>
        <p>
            Vesparum Capital provides independent capital markets advisory services to public and private companies to optimise their interactions with the equity capital markets. Vesparum operates as trusted in-house advisors to our clients, providing honest and unbiased advice in the collective best interests of a company’s shareholders.
        </p>
        <p>
            Vesparum's advice is informed by state-of-the-art proprietary databases yielding insights from over 16,000 capital markets transactions, the largest purpose-built capital raising database in Australia.
        </p>
        <p>
            Vesparum's team is approaching 30 employees. Vesparum hires professionals from tier 1 advisory firms, including Investment Banks, Management Consultancies and Legal Practices, as well as leading data scientists and developers.
        </p>
        <p>
            Vesparum maintains a Silicon Valley-esque culture.
        </p>
        <p>
            Vesparum was founded by brothers Tim and Ben Toner. Prior to Vesparum, Tim spent 12 years at Macquarie and Lazard Investment Banks. Tim now runs the Advisory team at Vesparum. Ben competed a PHD in Physics from Caltech, and runs the Data Science team at Vesparum.
        </p>
    </Section>
);

export default Press;
