export const SIZES = {
    MOBILE: 450,
    TABLET: 768,
    TABLET_LANDSCAPE: 1024,
    LAPTOP: 1200,
};

export const DEVICES = {
    MOBILE: `(min-width: ${SIZES.MOBILE}px)`,
    TABLET: `(min-width: ${SIZES.TABLET}px)`,
    TABLET_LANDSCAPE: `(min-width: ${SIZES.TABLET_LANDSCAPE}px)`,
    LAPTOP: `(min-width: ${SIZES.LAPTOP}px)`,
    MOBILE_MAX: `(max-width: ${SIZES.MOBILE}px)`,
    TABLET_MAX: `(max-width: ${SIZES.TABLET}px)`,
    TABLET_LANDSCAPE_MAX: `(max-width: ${SIZES.TABLET_LANDSCAPE}px)`,
    LAPTOP_MAX: `(max-width: ${SIZES.LAPTOP}px)`,
};
