import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Button } from './ContactForm';

const ThankYouTitle = styled.div`
    color: ${props => props.theme.graphite};
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 38px;
    margin-bottom: 10px;
`

const ThankYouMessage = styled.div`
    color: ${props => props.theme.graphite};
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 50px;
`

const ThankYou = ({ closeContactModal, referralType, referName }) => (
    <Fragment>
        <ThankYouTitle>Thanks for sending this through.</ThankYouTitle>
        <ThankYouMessage>
            We’re so glad you {referralType === 'get-in-touch' ? 'got in touch with us' : referralType === 'referred' ? `were referred to us by ${referName}` :  `referred ${referName} to us`} and we’ll be in touch with you within 2 business days!
        </ThankYouMessage>
        <Button onClick={closeContactModal} isVisible={1}>Close</Button>
    </Fragment>
);

export default ThankYou;
