import React, { Component, createRef } from 'react';
import styled from 'styled-components';
import { TweenMax } from 'gsap';

import { ReactComponent as Ellipse1 } from '../../assets/images/Ellipse1.svg';
import { ReactComponent as Ellipse2 } from '../../assets/images/Ellipse2.svg';
import { ReactComponent as Ellipse3 } from '../../assets/images/Ellipse3.svg';
import { DEVICES } from '../../constants/breakpoints';

const Ellipse1Anim = styled.div`
    pointer-events: none;
    position: absolute;
    right: 5%;
    top: 3%;
    width: 40%;
    z-index: 3;

    & > svg {
        width: 100%;
    }

    @media ${DEVICES.TABLET} {
        right: 0;
    }

    @media ${DEVICES.LAPTOP} {
        right: -25%;
        top: 25%;
    }
`;

const Ellipse2Anim = styled.div`
    pointer-events: none;
    position: absolute;
    right: 50%;
    top: 15%;
    width: 30%;
    z-index: 2;

    & > svg {
        width: 100%;
    }

    @media ${DEVICES.TABLET} {
        right: 35%;
    }

    @media ${DEVICES.LAPTOP} {
        right: 5%;
        top: 40%;
    }
`;

const Ellipse3Anim = styled.div`
    pointer-events: none;
    position: absolute;
    right: 20%;
    top: 27%;
    width: 30%;
    z-index: 1;

    & > svg {
        width: 100%;
    }

    @media ${DEVICES.TABLET} {
        right: 10%;
    }

    @media ${DEVICES.TABLET_LANDSCAPE} {
        top: 35%;
    }

    @media ${DEVICES.LAPTOP} {
        right: -15%;
        top: 55%;
    }
`;

class AnimatedCircles extends Component {
    constructor(props) {
        super(props);

        this.body = document.getElementsByTagName('body')[0];

        this.circleA = createRef();
        this.circleB = createRef();
        this.circleC = createRef();
    }

    componentDidMount() {
        // locks body from scrolling until the animation finishes
        this.body.setAttribute('style', 'overflow: hidden');

        const CA = this.circleA.current;
        const CB = this.circleB.current;
        const CC = this.circleC.current;

        if (CA && CB && CC) {
            TweenMax.fromTo(CA, 1, { y: '30vh', opacity: 0 }, { y: '0', opacity: 1, delay: 0.2, ease: 'sine.out' });
            TweenMax.fromTo(CB, 1, { y: '30vh', opacity: 0 }, { y: '0', opacity: 1, delay: 0.4, ease: 'sine.out' });
            TweenMax.fromTo(
                CC,
                1,
                { y: '30vh', opacity: 0 },
                {
                    y: '0',
                    opacity: 1,
                    delay: 0.6,
                    ease: 'sine.out',
                    onComplete: () => {
                        this.body.removeAttribute('style');
                        this.wiggle();
                    },
                },
            );
        }
    }

    wiggle = () => {
        const CA = this.circleA.current;
        const CB = this.circleB.current;
        const CC = this.circleC.current;

        if (CA && CB && CC) {
            TweenMax.to(CA, 1.5, {
                x: `${Math.random() * 5}%`,
                y: `${Math.random() * 5}%`,
                ease: 'sine.inOut',
            });
            TweenMax.to(CB, 1.5, {
                x: `${Math.random() * 5}%`,
                y: `${Math.random() * 5}%`,
                ease: 'sine.inOut',
            });
            TweenMax.to(CC, 1.5, {
                x: `${Math.random() * 5}%`,
                y: `${Math.random() * 5}%`,
                ease: 'sine.inOut',
                onComplete: () => this.wiggle(),
            });
        }
    };

    render() {
        return (
            <>
                {this.props.children}

                <Ellipse1Anim className="ellip1" ref={this.circleA}>
                    <Ellipse1 />
                </Ellipse1Anim>

                <Ellipse2Anim className="ellip2" ref={this.circleB}>
                    <Ellipse2 />
                </Ellipse2Anim>

                <Ellipse3Anim className="ellip3" ref={this.circleC}>
                    <Ellipse3 />
                </Ellipse3Anim>
            </>
        );
    }
}

export default AnimatedCircles;
