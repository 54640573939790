import { SET_SECTION_ACTIVE, UPDATE_ALL_SECTIONS } from './actionTypes';

export const setSectionActive = activeSection => {
    const { isInverted, name } = activeSection;

    return ({
        type: SET_SECTION_ACTIVE,
        payload: {
            isInverted,
            name
        }
    });
};

export const setUpdateAllSections = shouldUpdate => ({
    type: UPDATE_ALL_SECTIONS,
    payload: shouldUpdate
});
