import React from 'react';
import styled from 'styled-components';

const CompanyNameWrapper = styled.div`
    display: flex;
    flex-direction: row;
    opacity: ${props => props.isVisible ? 1 : 0};
    pointer-events: ${props => props.isVisible ? 'initial' : 'none'};
    transition: opacity .6s ease .5s;
    white-space: nowrap;
`

const InputWrapper = styled.div`
    margin-left: 10px;
    width: -webkit-fill-available;
`

const CompanyName = ({
    companyNameInput,
    isVisible,
    referralType,
    setCompanyVisible
}) => {
    if (isVisible) setCompanyVisible(true)  
    return (
        <CompanyNameWrapper isVisible={isVisible}>
            {referralType === `referred` ? `from` : `and I'm from`}<InputWrapper>{companyNameInput}</InputWrapper>
        </CompanyNameWrapper>
    );
};

export default CompanyName;
