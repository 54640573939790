import React, { useContext } from 'react';
import styled, { css } from 'styled-components';
import { Context } from '../../ContextState';
import Section from '../../components/Section';
import { Header } from '../../components/StyledComponents';
import { DEVICES } from '../../constants/breakpoints';
import AdvisoryTeam from '../../components/Team/AdvisoryTeam';
import DataScienceTeam from '../../components/Team/DataScienceTeam';
import teamGraphics from '../../assets/images/TeamGraphics.svg';

const CardsGroup = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    & > div:nth-of-type(2) {
        margin-top: 25px;
    }

    @media ${DEVICES.TABLET} {
        opacity: 0;
        transform: translateY(20vh);
        transition: transform 0.6s ease-out, opacity 0.2s ease-out;

        ${props =>
            props.isActive &&
            css`
                opacity: 1;
                transform: translateY(0);
                transition: transform 0.6s ease-out 0.6s, opacity 0.2s ease-out 0.6s;
            `}
    }

    @media ${DEVICES.TABLET_LANDSCAPE} {
        flex-direction: row;
        & > div:nth-of-type(2) {
            margin-top: 0;
        }
    }
`;

const StyledHeader = styled(Header)`
    @media ${DEVICES.TABLET} {
        opacity: 0;
        transform: translateY(20vh);
        transition: transform 0.6s ease-out, opacity 0.2s ease-out;

        ${props =>
            props.isActive &&
            css`
                opacity: 1;
                transform: translateY(0);
                transition: transform 0.6s ease-out 0.2s, opacity 0.2s ease-out 0.2s;
            `}
    }
`;

export const TeamBackground = styled.div`
    background-image: url(${teamGraphics});
    background-size: 500%;
    background-repeat: no-repeat;
    background-position: left bottom;
    position: absolute;
    bottom: 0;
    height: 100%;
    left: 0;
    width: 100%;

    @media ${DEVICES.TABLET} {
        background-size: 280%;
    }

    @media ${DEVICES.TABLET_LANDSCAPE} {
        background-size: 100%;
    }
`;

const ContentWrapper = styled.div`
    max-width: 1024px;
    margin: 0 auto;
    padding: 25px;
    position: relative;
    @media ${DEVICES.TABLET} {
        padding: 0;
    }
`;

const Team = () => {
    const { activeSection } = useContext(Context);
    const inView = activeSection.name === 'team';

    return (
        <Section displayName="team" invert name="team" fullWidth>
            <TeamBackground />
            <ContentWrapper>
                <StyledHeader invert underline isActive={inView}>
                    Team of 25 specialists
                </StyledHeader>
                <CardsGroup isActive={inView} className="cards-group">
                    <AdvisoryTeam />
                    <DataScienceTeam />
                </CardsGroup>
            </ContentWrapper>
        </Section>
    );
};

export default Team;
