import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import TweenMax from 'gsap';
import { DEVICES } from '../constants/breakpoints';

const Wrapper = styled.div`
    @media ${DEVICES.TABLET} {
        bottom: 30px;
        display: flex;
        flex-direction: column;
        opacity: ${props => (props.isVisible ? 1 : 0)};
        position: fixed;
        right: 21px;
        z-index: 999;
        bottom: 10px;
        right: 2px;
    }
`;

const Line = styled.div`
    background: ${props => props.theme.vivid};
    border-radius: 2px;
    bottom: 25px;
    margin-left: 50%;
    position: absolute;
    transform: translateX(-50%);
    width: 2px;
`;

const Background = styled.div`
    background: rgba(255, 255, 255, 0.1);
    border-radius: 20px;
    height: 50px;
    margin-left: 50%;
    transform: translateX(-50%);
    width: 32px;
`;

const Title = styled.div`
    color: ${props => props.theme.slate};
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    letter-spacing: 0.12em;
    line-height: 0px;
    opacity: 0.8;
    text-transform: uppercase;
    transform: rotate(90deg) translateX(-50px);
`;

const ScrollDownButton = ({ isVisible = true }) => {
    const line = useRef();

    const mobile = window.innerWidth < 768;

    useEffect(() => {
        const animateLine = () => {
            TweenMax.fromTo(
                line.current,
                1,
                {
                    height: 0,
                    y: -42,
                    ease: 'sine.out',
                },
                {
                    height: 42,
                    y: 0,
                    ease: 'sine.out',
                    delay: 0.3,
                    immediateRender: false,
                    onComplete: () => animateOut(),
                },
            );

            const animateOut = () => {
                TweenMax.fromTo(
                    line.current,
                    1,
                    {
                        height: 42,
                        y: 0,
                        ease: 'sine.out',
                    },
                    {
                        height: 0,
                        y: 0,
                        delay: 0.3,
                        ease: 'sine.out',
                        immediateRender: false,
                        onComplete: () => animateLine(),
                    },
                );
            };
        };
        animateLine();
    }, []);

    return (
        !mobile && (
            <Wrapper isVisible={isVisible}>
                <Title>SCROLL</Title>
                <Line ref={line} />
                <Background />
            </Wrapper>
        )
    );
};

export default ScrollDownButton;
