import React, { useContext } from 'react';
import styled, { css } from 'styled-components';
import { Context } from '../../ContextState';
import { DEVICES } from '../../constants/breakpoints';
import Section from '../../components/Section';
import {
    CardBackground,
    CardContent,
    CardHeader,
    Header,
    SectionContent,
    SectionIntro,
} from '../../components/StyledComponents';
import { ReactComponent as Growth } from '../../assets/images/icons/careers/Growth.svg';
import { ReactComponent as Mentorship } from '../../assets/images/icons/careers/Mentorship.svg';
import { ReactComponent as Autonomy } from '../../assets/images/icons/careers/Autonomy.svg';

const CardWrapper = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    max-width: 340px;
    padding: 20px;

    @media ${DEVICES.TABLET} {
        opacity: 0;
        transform: translateY(20vh);
        transition: transform 0.6s ease-out, opacity 0.2s ease-out;

        /* Animate elements when section scrolls into view */
        ${props =>
            props.isActive &&
            css`
            opacity: 1;
            transform: translateY(0);
            transition: transform .6s ease-out, opacity .2s ease-out;

            ${props =>
                props.order === 1 &&
                css`
                    transition-delay: 0.4s;
                `}

            ${props =>
                props.order === 2 &&
                css`
                    transition-delay: 0.6s;
                `}

            ${props =>
                props.order === 3 &&
                css`
                    transition-delay: 0.8s;
                `}
        `}
    }
`;

const StyledSectionContent = styled(SectionContent)`
    display: flex;
    flex-direction: column;
    justify-content: spaced-evenly;

    @media ${DEVICES.TABLET} {
        flex-direction: row;
    }
`;

const StyledHeader = styled(Header)`
    @media ${DEVICES.TABLET} {
        opacity: 0;
        transform: translateY(20vh);
        transition: transform 0.6s ease-out, opacity 0.2s ease-out;

        ${props =>
            props.isActive &&
            css`
                opacity: 1;
                transform: translateY(0);
                transition: transform 0.6s ease-out 0.2s, opacity 0.2s ease-out 0.2s;
            `}
    }
`;

const IconWrapper = styled.div`
    height: 71px;
    width: 30%;

    & > svg {
        height: auto;
        width: 100%;
    }
`;

const WhatWeOffer = () => {
    const { activeSection } = useContext(Context);
    const inView = activeSection.name === 'what-we-offer';
    return (
        <Section displayName="what we offer" name="what-we-offer">
            {/* <SectionContentWrapper> */}
            <SectionIntro>
                <StyledHeader underline isActive={inView}>
                    What we offer
                </StyledHeader>
            </SectionIntro>
            <StyledSectionContent>
                {/* <Left> */}
                <CardWrapper isActive={inView} order={1}>
                    <CardBackground background="vivid">
                        <IconWrapper>
                            <Growth style={{ width: '90px', height: '69px' }} />
                        </IconWrapper>
                        <CardHeader>Growth</CardHeader>
                        <CardContent>
                            Fast-track your career through the quality of people and clients you work with.
                        </CardContent>
                    </CardBackground>
                </CardWrapper>
                <CardWrapper isActive={inView} order={2}>
                    <CardBackground background="slate">
                        <IconWrapper>
                            <Mentorship />
                        </IconWrapper>
                        <CardHeader>Culture</CardHeader>
                        <CardContent>
                            Join a team who love their work, and who also understand the importance of a balanced life.
                        </CardContent>
                    </CardBackground>
                </CardWrapper>
                <CardWrapper isActive={inView} order={3}>
                    <CardBackground background="darkSlate">
                        <IconWrapper>
                            <Autonomy />
                        </IconWrapper>
                        <CardHeader colour="vivid">Purpose</CardHeader>
                        <CardContent colour="vivid">
                            We share a common vision which solves for the best interests of companies and their
                            shareholders. Always.
                        </CardContent>
                    </CardBackground>
                </CardWrapper>
                {/* </Left> */}

                {/* <Right> */}
                {/* <CardWrapper isActive={inView} order={2}>
                    <CardBackground background="slate">
                        <IconWrapper>
                            <Mentorship />
                        </IconWrapper>
                        <CardHeader>Culture</CardHeader>
                        <CardContent>
                            Join a team who love their work, and who also understand the importance of a balanced life.
                        </CardContent>
                    </CardBackground>
                </CardWrapper>
                {/* </Right> */}
            </StyledSectionContent>
            {/* </SectionContentWrapper> */}
        </Section>
    );
};

export default WhatWeOffer;
