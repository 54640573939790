import React from 'react';
import styled from 'styled-components';
import { DEVICES } from '../../../constants/breakpoints';

const DropdownWrapper = styled.div`
    display: inline-block;
    opacity: ${props => props.isVisible ? 1 : 0};
    position:relative;
    transition: opacity .6s ease .5s;

    &:after, &:before {
        content: '';
        display: block;
        position: absolute;
        background: ${props => props.theme.graphite};
        height: 12px;
        position: absolute;
        top: 50%;
        width: 4px;
    }

    &:after {
        right: 9px;
        transform: rotate(45deg) translateY(-50%);
    }

    &:before {
        right: 7px;
        transform: rotate(-45deg) translateY(-50%);
    }
`

const Dropdown = styled.select`
    appearance: none;   
    background: transparent;
    border: 0;
    border-bottom: 2px solid ${props => props.theme.vision};
    border-radius: 0;
    cursor: pointer;
    line-height: 30px;
    margin-right: 30px;
    position: relative;
    width: 100%;
    z-index:9;

    &:focus {
        border-radius: 0;
        outline: 0;
    }

    @media ${DEVICES.TABLET_LANDSCAPE} {
        line-height: 50px;
    }
`

const DropdownComponent = ({
    handleDropdown,
    isVisible,
    referralType
}) => (
    <DropdownWrapper isVisible={isVisible}>
        <Dropdown value={referralType} onChange={handleDropdown} tabIndex={isVisible ? 0 : -1}>
            <option value={`referred`}>and I'm being referred by</option>
            <option value={`referring`}>and I'm referring</option>
            <option value={`get-in-touch`}>and I want to get in touch</option>
        </Dropdown>
    </DropdownWrapper>
);

export default DropdownComponent;