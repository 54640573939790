import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Context } from '../ContextState';
import { DEVICES } from '../constants/breakpoints';

const Wrapper = styled.div`
    display: none;

    @media ${DEVICES.LAPTOP} {
        display: block;
        position: fixed;
        right: 32px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 999;
    }
`;

const Bar = styled.div`
    background-color: ${props =>
        props.active ? props.theme.vision : props.invert ? props.theme.graphite : props.theme.vivid};
    display: block;
    height: ${props => (props.active ? 4 : 2)}px;
    left: 50%;
    margin: 20px 0;
    transform: translateX(-50%);
    transition: height 0.2s ease, width 0.2s ease;
    width: ${props => (props.active ? 16 : 8)}px;

    &:hover {
        height: 4px;
        width: 16px;
    }
`;

const SectionIndicator = () => {
    const { activeSection } = useContext(Context);
    const { pathname } = useLocation();
    const [sections, setSections] = useState([]);

    const getSections = () => {
        const sectionsDOM = document.getElementsByClassName('section');
        let sections = [];

        for (const sec of sectionsDOM) {
            sections = [...sections, sec.dataset.name];
        }
        setSections(sections);
    };

    const scrollToSection = e => {
        const sectionName = e.target.getAttribute('name');
        const sectionDOM = document.getElementById(sectionName);
        const { offsetTop } = sectionDOM;

        window.scrollTo(0, offsetTop);
    };

    useEffect(() => {
        getSections();
    }, [pathname]);

    const invert = activeSection.isInverted;

    // if there is only one section (+ Footer is also section - which makes it 2) do not display
    return (
        sections.length > 2 && (
            <Wrapper>
                {sections.map(name => {
                    const active = activeSection.name === name || activeSection.parent === name;
                    return (
                        <Bar
                            active={active ? 1 : 0}
                            invert={invert ? 1 : 0}
                            key={name}
                            name={name}
                            onClick={scrollToSection}
                        />
                    );
                })}
            </Wrapper>
        )
    );
};

export default SectionIndicator;
