import { useEffect, useState } from 'react';

const useScrollPosition = () => {
  const [ position, setPosition ] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const setFromEvent = e => {
      setPosition({ x: window.pageXOffset, y: window.pageYOffset })
    };
    window.addEventListener('scroll', setFromEvent);

    return () => {
      window.removeEventListener('scroll', setFromEvent);
    };
  }, []);

  return position;
};

export default useScrollPosition;