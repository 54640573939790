import React, { useContext } from 'react';
import styled, { css } from 'styled-components';
import Section from '../components/Section';
import { Header, SectionContent, SectionIntro } from '../components/StyledComponents';
import { Context } from '../ContextState';
import { DEVICES } from '../constants/breakpoints';
import Footer from '../components/Footer';

const StyledSectionContent = styled(SectionContent)`
    display: flex;
    flex-direction: column;
    justify-content: spaced-evenly;

    @media ${DEVICES.TABLET} {
        flex-direction: row;
    }
`;

const StyledHeader = styled(Header)`
    @media ${DEVICES.TABLET} {
        opacity: 0;
        transform: translateY(20vh);
        transition: transform 0.6s ease-out, opacity 0.2s ease-out;

        ${props =>
    props.isActive &&
    css`
                opacity: 1;
                transform: translateY(0);
                transition: transform 0.6s ease-out 0.2s, opacity 0.2s ease-out 0.2s;
            `}
    }
`;

const Explanation = styled.div`
    font-style: normal;
    font-weight: 250;
    font-size: 14px;
    margin-bottom: 60px;
`;

const StyledArticle = styled.div`
    padding-bottom: 20px;
    padding-top: 20px;
    font-weight: 250;
    margin-bottom: 20px;
    border-bottom: 1px solid ${props => props.theme.vivid};
`;

const Date = styled.div`
    font-size: 14px;
    margin-bottom: 10px;
`;

const Title = styled.div`
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 10px;
`;

const TitleLink = styled.a`
    color: ${props => props.theme.vivid};
    text-decoration: none;
`;

const SubTitle = styled.div`
    font-size: 16px;
    margin-bottom: 10px;
`;

const Article = ({ date, title, subtitle, link }) => {
    return (
        <>
            <StyledArticle>
                <Date>{date}</Date>
                <Title>
                    <TitleLink target="_blank" href={link}>
                        {title}
                    </TitleLink>
                </Title>
                <SubTitle>{subtitle}</SubTitle>
            </StyledArticle>
        </>
    );
};

const MediaWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const Media = () => {
    const { activeSection } = useContext(Context);
    const inView = activeSection.name === 'media';
    return (
        <>
            <Section name="media">
                <SectionIntro>
                    <StyledHeader underline isActive>
                        In the media
                    </StyledHeader>
                </SectionIntro>
                <StyledSectionContent>
                    <MediaWrapper>
                        <Article
                            date="25 January 2021"
                            title="Did retail investors get shafted in the ASX’s post-Covid capital rush? Yes. Yes they did."
                            subtitle="Stockhead&nbsp; | &nbsp;Sam Jacobs"
                            link="https://stockhead.com.au/news/did-retail-investors-get-shafted-in-the-asxs-post-covid-capital-rush-yes-yes-they-did/"
                        />
                        <Article
                            date="21 January 2021"
                            title="The great rip-off: Investors short-changed as institutions gorge on capital raisings"
                            subtitle="InQueensland&nbsp; | &nbsp;John McCarthy"
                            link="https://inqld.com.au/business/2021/01/21/the-great-rip-off-investors-short-changed-as-institutions-gorge-on-capital-raisings/"
                        />
                        <Article
                            date="20 January 2021"
                            title="Investors short-changed by $3.6b in capital raisings"
                            subtitle="AFR Exclusive&nbsp; | &nbsp;Aleks Vickovich"
                            link="https://www.afr.com/markets/equity-markets/investors-short-changed-by-3-6b-in-capital-raisings-20210119-p56vc7"
                        />
                        <Article date="1 October 2020" title="The challenge of corporate balance sheets in 2020"
                                 subtitle="Company Director Magazine&nbsp; | &nbsp;Christopher Niesche"
                                 link="https://aicd.companydirectors.com.au/membership/company-director-magazine/2020-back-editions/october/the-challenge-of-corporate-balance-sheets-in-2020"/>
                        <Article date="8 September 2020"
                                 title="What is the ACCC trying to achieve with its criminal cartel case?"
                                 subtitle="AFR Opinion&nbsp; | &nbsp;Karen Maley"
                                 link="https://www.afr.com/companies/financial-services/what-is-the-accc-trying-to-achieve-with-its-criminal-cartel-case-20200831-p55qtp"/>
                        <Article date="6 August 2020" title="ANZ cartel case lifts cost of capital"
                                 subtitle="AFR Chanticleer&nbsp; | &nbsp;Tony Boyd"
                                 link="https://www.afr.com/chanticleer/anz-cartel-case-lifts-cost-of-capital-20200805-p55iny"/>
                        <Article date="5 August 2020" title="Vesparum preparing for second round of capital raising"
                                 subtitle="Ausbiz TV&nbsp; | &nbsp;Nadine Blaney"
                                 link="https://www.ausbiz.com.au/media/vesperum-preparing-for-second-round-of-capital-raising?videoId=3213"/>
                        <Article date="18 July 2020" title="Investors 'take a punt' on Australia's riskiest stocks"
                                 subtitle="AFR Exclusive&nbsp; | &nbsp;Aleks Vickovich"
                                 link="https://www.afr.com/wealth/investing/investors-take-a-punt-on-australia-s-riskiest-stocks-20200716-p55crr"/>
                        <Article date="17 July 2020"
                                 title="Is this $9 billion splurge really a bull market?"
                                 subtitle="Crikey&nbsp; | &nbsp;John McCarthy"
                                 link="https://www.crikey.com.au/2020/07/17/is-this-9-billion-splurge-really-a-bull-market-or-an-entirely-different-kind-of-bull/"/>
                        <Article date="15 July 2020" title="Retail investors tumble in while professionals sell off"
                                 subtitle="2GB/3AW Money News&nbsp; | &nbsp;Scott Haywood"
                                 link="https://omny.fm/shows/money-news-with-ross-greenwood/retail-investors-tumble-in-while-professionals-sel "/>
                        <Article date="13 July 2020"
                                 title="Why Australia's robinhood investors should be angry"
                                 subtitle="AFR Chanticleer&nbsp; | &nbsp;James Thomson"
                                 link="https://www.afr.com/chanticleer/why-australia-s-robinhood-investors-should-be-angry-20200713-p55biu"/>
                        <Article date="13 July 2020"
                                 title="Retail investors pile in as professionals exit"
                                 subtitle="AFR Exclusive&nbsp; | &nbsp;Aleks Vickovich"
                                 link="https://www.afr.com/markets/equity-markets/retail-investors-pile-in-as-professionals-exit-20200710-p55ayi"/>
                        <Article date="25 May 2020" title="Capital issues deliver solid performances"
                                 subtitle="Morningstar&nbsp; | &nbsp;Nicki Bourlioufas"
                                 link="https://www.morningstar.com.au/stocks/article/capital-issues-deliver-solid-performances/202651"/>
                        <Article date="21 May 2020" title="Bankers rake in $200m off top 10 COVID-19 capital raisings"
                                 subtitle="AFR Exclusive&nbsp; | &nbsp;Aleks Vickovich"
                                 link="https://www.afr.com/companies/financial-services/bankers-rake-in-200m-off-top-10-covid-19-capital-raisings-20200521-p54v4u"/>
                        <Article date="24 April 2020" title="LNP senator pushed for more disclosure in capital raisings"
                                 subtitle="AFR&nbsp; | &nbsp;Aleks Vickovich and James Thomson"
                                 link="https://www.afr.com/companies/financial-services/lnp-senator-pushed-for-more-disclosure-in-capital-raisings-20200424-p54mwa"/>
                        <Article date="23 April 2020" title="Beware 'boilerplate' disclosure, ASIC warns market"
                                 subtitle="AFR&nbsp; | &nbsp;Aleks Vickovich and James Thomson"
                                 link="https://www.afr.com/markets/equity-markets/beware-boilerplate-disclosure-asic-warns-market-20200423-p54mgw"/>
                        <Article date="22 April 2020" title="COVID-19 trumps GFC for share issue discounts, dilutions"
                                 subtitle="AFR Exclusive&nbsp; | &nbsp;Aleks Vickovich"
                                 link="https://www.afr.com/wealth/investing/covid-19-trumps-gfc-for-share-issue-discounts-dilutions-20200421-p54lrs"/>
                        <Article date="18 April 2020"
                                 title="Everything you need to know about COVID-19 capital raisings"
                                 subtitle="AFR&nbsp; | &nbsp;Aleks Vickovich"
                                 link="https://www.afr.com/wealth/personal-finance/everything-you-need-to-know-about-covid-19-capital-raisings-20200415-p54jwv"/>
                    </MediaWrapper>
                </StyledSectionContent>
            </Section>
            <Footer visible/>
        </>
    );
};
export default Media;
