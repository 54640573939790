import React, { useContext } from 'react';
import Section from '../../components/Section';
import HeroVideo from '../../components/Animations/HeroVideo';
import { Context } from '../../ContextState';

const Hero = () => {
    const { activeSection } = useContext(Context);
    const inView = activeSection.name === 'hero';
    return (
        <Section minHeight fullWidth gradient noOverflow name="hero">
            <HeroVideo active={inView} />
        </Section>
    );
};

export default Hero;
