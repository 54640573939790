import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

import { DEVICES } from '../constants/breakpoints';

export const Description = styled.div`
    color: ${props => props.theme[props.colour] || props.theme.slate};
    font-size: ${props => (props.hero ? 20 : 16)}px;
    font-weight: 300;
    line-height: 28px;
    margin-bottom: 15px;
    position: relative;
    text-align: ${props => props.align || 'left'};
    width: 100%;
    z-index: 1;

    @media ${DEVICES.LAPTOP} {
        width: ${props => props.width || 'auto'};
    }
`;

export const SectionContentWrapper = styled.div`
    display: flex;
    flex-direction: column;

    @media ${DEVICES.LAPTOP} {
        flex-direction: row;
    }
`;

export const SectionIntro = styled.div`
    flex: 2;
`;

export const SectionContent = styled.div`
    flex: 3;
    margin-left: 0;

    @media ${DEVICES.LAPTOP} {
        margin-left: 5%;
    }
`;

export const Header = styled.h1`
    color: ${props => (props.invert ? props.theme.graphite : props.theme.vivid)};
    font-style: normal;
    font-weight: bold;
    font-size: 38px;
    line-height: 42px;
    margin: 0 0 20px 0;
    opacity: inherit;
    position: relative;
    z-index: 999;

    ${props =>
        props.underline &&
        css`
            margin-bottom: 50px;

            &:after {
                background: ${props => props.theme.vision};
                content: '';
                height: 8px;
                left: 0;
                position: absolute;
                top: 115%;
                width: ${props => props.underlineLength || 34}px;
            }

            @media ${DEVICES.LAPTOP} {
                margin-bottom: 60px;
            }
        `}

    @media ${DEVICES.LAPTOP} {
        font-weight: normal;
        line-height: 1;
        font-size: ${props => (props.hero ? '90px' : '70px')};

        ${props =>
            props.large &&
            css`
                padding: 150px 0;
            `}
    }
`;

export const SubHeader = styled.h2`
    color: ${props => (props.invert ? props.theme.graphite : props.theme.vivid)};
    font-style: normal;
    font-weight: normal;
    font-size: 28px;
    line-height: 36px;
    margin: 10px 0;

    @media ${DEVICES.LAPTOP} {
        font-size: 38px;
        font-weight: normal;
        line-height: 50px;
    }
`;

export const BulletedList = styled.ul`
    list-style-position: outside;
    margin: 0 0 0 30px;
    padding: 0;
`;

export const CloseButton = styled.div`
    cursor: pointer;
    display: ${props => (props.isHidden ? 'none' : 'block')};
    height: 20px;
    position: relative;
    width: 20px;

    &:after,
    &:before {
        background: ${props => (props.invert ? props.theme.graphite : props.theme.vivid)};
        content: '';
        height: 3px;
        position: absolute;
        top: 5px;
        width: 20px;
    }

    &:after {
        transform: rotate(45deg);
    }

    &:before {
        transform: rotate(-45deg);
    }
`;

export const CardBackground = styled.div`
    background-color: ${props => props.theme[props.background]};
    box-shadow: 10px 10px ${props => props.theme[props.background === 'slate' ? 'vivid' : 'slate']};
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    padding: 85px 35px;
`;

export const CardContent = styled.div`
    color: ${props => props.theme[props.colour] || props.theme.graphite};
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
`;

export const CardHeader = styled.div`
    color: ${props => props.theme[props.colour] || props.theme.graphite};
    font-style: normal;
    font-weight: normal;
    font-size: 28px;
    line-height: 36px;
    margin: 20px 0 5px 0;
`;
