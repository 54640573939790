import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const StyledLink = styled(Link)`
    color: ${props => (props.color ? props.theme[props.color] : props.theme.darkSlate)};
    margin-left: 5px;
    position: relative;
    text-decoration: none;
    font-weight: 400;

    &:first-child {
        margin-left: 0;
    }

    &:after {
        background: ${props => props.theme.vision};
        bottom: -1px;
        content: '';
        display: block;
        height: 1px;
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
        transition: width 0.2s ease;
        width: 0;
    }

    &:hover {
        color: ${props => props.theme.vision};

        &:after {
            transition: width 0.2s ease;
            width: 100%;
        }
    }
`;

// this can navigate between sections (logic copied from cta button component)
const SmartLink = ({ hash, children, color, to }) => {
    const scrollToSection = e => {
        const sectionName = e.target.getAttribute('name');
        const sectionDOM = document.getElementById(sectionName);

        if (sectionDOM) {
            const { offsetTop } = sectionDOM;

            window.scrollTo(0, offsetTop);
        }
    };

    return hash ? (
        <StyledLink color={color} name={hash} onClick={scrollToSection}>
            {children}
        </StyledLink>
    ) : (
        <StyledLink color={color} to={to}>
            {children}
        </StyledLink>
    );
};

export default SmartLink;
