import React, { useContext, useEffect, useRef } from 'react';
// import TweenMax from 'gsap';
import { Context } from '../../ContextState';
import TeamCard from './TeamCard';
import DataScienceTeamModal from './DataScienceTeamModal';
import { Title, Icon, CardCopy } from './TeamStyledComponents';
import { ReactComponent as DataScienceIcon } from '../../assets/images/icons/homepage/DataScienceTeam.svg';

const DataScienceTeam = () => {
    const { openModal } = useContext(Context);
    const openDataScienceModal = () => openModal(<DataScienceTeamModal />);

    // useEffect(() => {
    //     // wiggle();
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    const icon = useRef();

    // const wiggle = () => {
    //     if (icon.current) {
    //         TweenMax.to(icon.current, 1.5, {
    //             x: `${Math.random() * 5}%`,
    //             y: `${Math.random() * 5}%`,
    //             ease: 'sine.inOut',
    //             onComplete: () => wiggle(),
    //         });
    //     }
    // };

    return (
        <TeamCard clickThrough={openDataScienceModal} founderLink="Biography - Dr Ben Toner">
            <Icon ref={icon}>
                <DataScienceIcon />
                <Title invert style={{ 'padding-top': '20px' }}>
                    Data science
                </Title>
            </Icon>
            <CardCopy invert>
                <p>
                    Combining world-class academic backgrounds in quantum physics, mathematics and actuarial sciences
                    which puts us at the forefront of equity capital markets innovation globally.
                </p>
                <p>
                    Predictive models and insights based on a 10+ years investment in data collection and validation.
                    Importantly, our data science team all have a deep understanding and interest in Australia's equity
                    capital markets.
                </p>
            </CardCopy>
        </TeamCard>
    );
};

export default DataScienceTeam;
