import styled, { css } from 'styled-components';
import { DEVICES } from '../../constants/breakpoints';

export const ProfileWrapper = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;

    @media ${DEVICES.TABLET} {
        flex-direction: row;
    }
`;

export const ProfilePicture = styled.img`
    border-radius: 50%;
    height: 120px;
    margin: 10px 20px 10px 0;
    width: 120px;
`;

export const ModalContent = styled.div`
    color: ${props => props.theme.graphite};
    padding: 30px 5px 5px 5px;
`;

export const Content = styled.div`
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 26px;
`;

export const Title = styled.h2`
    color: ${props => (props.invert ? props.theme.vivid : props.theme.graphite)};
    font-style: normal;
    font-weight: normal;
    font-size: 28px;
    line-height: 42px;
    margin: 0 0 10px 0;
    position: relative;

    ${props =>
        props.underline &&
        css`
            margin-bottom: 15px;

            &:after {
                background: ${props => props.theme.vision};
                content: '';
                height: 8px;
                left: 0;
                position: absolute;
                top: 115%;
                width: 34px;
            }

            @media ${DEVICES.LAPTOP} {
                margin-bottom: 30px;
            }
        `}

    @media ${DEVICES.TABLET} {
        font-size: 38px;
    }
`;

export const Name = styled.div`
    display: block;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 28px;
`;

export const Role = styled.div`
    display: block;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    // text-transform: uppercase;
`;

export const NameWrapper = styled.div`
    text-align: center;

    @media ${DEVICES.TABLET} {
        text-align: left;
    }
`;

export const CardCopy = styled.div`
    color: ${props => (props.invert ? props.theme.vivid : props.theme.graphite)};
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 26px;
    margin-top: 10px;
`;

export const BulletsWrapper = styled.div`
    display: flex;
    flex-direction: column;

    @media ${DEVICES.TABLET} {
        flex-direction: row;

        & > ul:nth-child(2) {
            margin-left: 20px;
        }
    }
`;

export const Icon = styled.div`
    height: 100px;
    display: flex;
    & > svg {
        margin-right: 20px;
    }
`;
