import React, { useLayoutEffect, useEffect, useState, useContext, useRef } from 'react';
import styled, { css } from 'styled-components';
import { useLocation } from 'react-router-dom';
import { Context } from '../../ContextState';
import useScrollPosition from '../../utils/useScrollPosition';
import { DEVICES } from '../../constants/breakpoints';

const InnerWrapper = styled.div`
    padding: 80px 25px 50px 25px;

    ${props =>
        props.fullWidth &&
        css`
            padding-left: 0;
            padding-right: 0;
        `}

    @media ${DEVICES.TABLET} {
        padding: 100px 50px 50px 50px;

        ${props =>
            props.fullWidth &&
            css`
                padding-left: 0;
                padding-right: 0;
            `}
    }

    @media ${DEVICES.LAPTOP} {
        padding: 100px 0 50px 0;
    }
`;

const Wrapper = styled.div`
    background: ${props => (props.invert ? props.theme.vivid : props.theme.graphite)};
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    justify-content: ${props => props.vertical || 'center'};
    max-width: ${props => (props.fullWidth ? 'none' : '1024px')};
    margin: 0 auto;
    opacity: 1;
    transition: opacity 0.2s ease;
    position: relative;

    @media ${DEVICES.TABLET} {
        background: ${props => props.theme[props.background] || 'transparent'};
        opacity: ${props => (props.active ? 1 : 0)};
    }

    ${props =>
        props.minHeight &&
        css`
            min-height: 100vh;
        `}

    ${props =>
        props.minHeightWithLimit &&
        css`
            min-height: max(100vh, 860px);
        `}

    ${props =>
        props.noOverflow &&
        css`
            overflow: hidden;
        `}

    ${props =>
        props.id === 'footer' &&
        css`
            justify-content: flex-end;
        `}

    ${props =>
        props.gradient &&
        css`
            background: rgb(30, 30, 34);
            background: linear-gradient(
                180deg,
                rgba(30, 30, 34, 1) 0%,
                rgba(30, 30, 34, 1) 0%,
                rgba(30, 30, 34, 1) 70%,
                rgba(30, 30, 34, 0.7791491596638656) 80%,
                rgba(30, 30, 34, 0.40379901960784315) 90%,
                rgba(30, 30, 34, 0) 100%
            );

            @media ${DEVICES.LAPTOP} {
                background: transparent;
            }
        `}
    ${props =>
        props.visible &&
        css`
            opacity: 1;
            position: relative;
            bottom: auto;
            left: auto;
            transform: none;
        `}

    ${props =>
        props.id === 'hero' &&
        css`
            position: relative;
            min-height: 0;

            ${InnerWrapper} {
                padding: 0;
            }

            @media ${DEVICES.TABLET} {
                min-height: 100vh;

        `}

    ${props =>
        props.id === 'track-record' &&
        css`
            ${InnerWrapper} {
                padding-top: 50px;
            }
        `}
`;

const Section = ({
    background,
    children,
    displayName,
    fullWidth,
    invert = false,
    minHeight,
    minHeightWithLimit,
    name,
    noOverflow,
    vertical,
    visible,
}) => {
    const [sectionOffset, setSectionOffset] = useState(0);
    const [sectionHeight, setSectionHeight] = useState(0);
    const [active, setActive] = useState(false);

    const {
        activeSection,
        activeSection: { updateAll },
        setSectionActive,
        setUpdateAllSections,
    } = useContext(Context);

    const scrollTop = useScrollPosition().y;
    const ref = useRef();
    const pathname = useLocation();

    const setSectionDimentions = () => {
        setSectionOffset(ref.current.offsetTop);
        setSectionHeight(ref.current.getBoundingClientRect().height);
    };
    // sets dimentions for each section
    useLayoutEffect(() => {
        setSectionDimentions();
        setUpdateAllSections(false);
        // reset section dimentions on window resize
        window.addEventListener('resize', setSectionDimentions);
        return () => {
            window.removeEventListener('resize', setSectionDimentions);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref, pathname, updateAll]);

    const windowHeight = window.innerHeight;
    const sectionFold = sectionOffset - windowHeight / 2;

    if (scrollTop > sectionFold && scrollTop < sectionFold + sectionHeight && !active) {
        setActive(true);
        setSectionActive({ name, isInverted: invert });
    } else if ((scrollTop < sectionFold || scrollTop > sectionFold + sectionHeight) && active) {
        setActive(false);
    }

    useEffect(() => {
        const section = ref.current.parentElement;
        section.scrollTop = 0;
    }, [active]);

    // className allows to grab sections for navigation - Menu.js in TopBar.js
    // data-name is to dynamically generate navigation for each page (SectionIndicator.js)
    // data-nav is a display name for Menu.js in TopBar.js (not all sections are in the menu)
    return (
        <Wrapper
            active={active}
            background={background}
            className="section"
            data-name={name}
            data-nav={displayName}
            fullWidth={fullWidth}
            id={name}
            invert={invert}
            minHeight={minHeight}
            minHeightWithLimit={minHeightWithLimit}
            noOverflow={noOverflow}
            ref={ref}
            vertical={vertical}
            visible={visible}
        >
            <InnerWrapper fullWidth={fullWidth}>{children}</InnerWrapper>
        </Wrapper>
    );
};

export default Section;
