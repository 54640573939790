import React from 'react';
import styled from 'styled-components';

const CommentWrapper = styled.div`
    opacity: ${props => props.isVisible ? 1 : 0};
    pointer-events: ${props => props.isVisible ? 'initial' : 'none'};
    transition: opacity .6s ease .5s;
`

const Comment = ({
    commentInput,
    isVisible,
    setCommentVisible
}) => {
    if (isVisible) setCommentVisible(true)
    return (
        <CommentWrapper isVisible={isVisible}>{commentInput}</CommentWrapper>
    );
}

export default Comment;