import React, { useContext, useState } from 'react';
import styled, { css } from 'styled-components';
import { Context } from '../../ContextState';
import { DEVICES } from '../../constants/breakpoints';
import Section from '../../components/Section';
import {
    Description,
    Header,
    SectionContent,
    SectionContentWrapper,
    SectionIntro,
    SubHeader,
} from '../../components/StyledComponents';
import Accordion from '../../components/Accordion';

const Link = styled.a`
    color: ${props => props.theme.graphite};
    display: inline-block;
    font-weight: bold;
    text-decoration: none;
    transition: color 0.2s ease;
    position: relative;

    &:after {
        background-color: ${props => props.theme.graphite};
        bottom: 5px;
        content: '';
        display: block;
        height: 2px;
        left: 0;
        position: absolute;
        transition: color 0.2s ease, width 0.2s ease;
        width: 0;
    }

    &:hover {
        &:after {
            width: 100%;
        }
    }
`;

const StyledSectionContentWrapper = styled(SectionContentWrapper)`
    margin-bottom: 30px;
`;

const AccordionWrapper = styled.div`
    @media ${DEVICES.TABLET} {
        opacity: 0;
        transform: translateY(20vh);
        transition: transform 0.6s ease-out, opacity 0.2s ease-out;

        ${props =>
            props.isActive &&
            css`
                opacity: 1;
                transform: translateY(0);
                transition: transform 0.6s ease-out, opacity 0.2s ease-out;

                ${props =>
                    props.order === 1 &&
                    css`
                        transition-delay: 0.8s;
                    `}

                ${props =>
                    props.order === 2 &&
                    css`
                        transition-delay: 1s;
                    `}
            `}
    }
`;

const StyledHeader = styled(Header)`
    @media ${DEVICES.TABLET} {
        opacity: 0;
        transform: translateY(20vh);
        transition: transform 0.6s ease-out, opacity 0.2s ease-out;

        ${props =>
            props.isActive &&
            css`
                opacity: 1;
                transform: translateY(0);
                transition: transform 0.6s ease-out 0.2s, opacity 0.2s ease-out 0.2s;
            `}
    }
`;

const StyledDescription = styled(Description)`
    @media ${DEVICES.TABLET} {
        opacity: 0;
        transform: translateY(20vh);
        transition: transform 0.6s ease-out, opacity 0.2s ease-out;

        ${props =>
            props.isActive &&
            css`
                opacity: 1;
                transform: translateY(0);
                transition: transform 0.6s ease-out 0.4s, opacity 0.2s ease-out 0.4s;
            `}
    }
`;

const SubmitAddress = styled.div`
    font-size: 16px;
    line-height: 26px;
    font-weight: 300;
`;

const readMoreCopyExperienced = (
    <>
        <br />
        Backgrounds with a combination of engineering and commerce are highly desirable. We value broad client-facing
        experience, and a commitment to our core values of integrity, empathy and humility.
        <br />
    </>
);

// TODO "To apply for one of our internship positions, " is there so that 'careers@vesparum.com' isn't on a line by
// itself - if it were the "Read more" link would hover over the text.
const readMoreCopyInterns = (
    <>
        <br />
        Our program provides a unique opportunity to work with specialists from a wide range of professional services
        and technology backgrounds, and build valuable skills through broad exposure to Australia’s equity capital
        markets.
        <br />
    </>
);

const Positions = () => {
    const [hasContentChanged, setContentChanged] = useState(false);

    const { activeSection } = useContext(Context);
    const inView = activeSection.name === 'positions';

    return (
        <Section displayName="positions" invert name="positions" shouldSectionUpdate={hasContentChanged}>
            <StyledSectionContentWrapper>
                <SectionIntro>
                    <StyledHeader invert underline isActive={inView}>
                        Positions
                    </StyledHeader>
                    <StyledDescription colour="graphite" hero isActive={inView}>
                        <Link href="https://careers.vesparum.com">Click here to see current vacancies.</Link>
                    </StyledDescription>
                    <StyledDescription colour="graphite" hero isActive={inView}>
                        If there are no current vacancies that fit your background and experience please email us at{' '}
                        <Link href="mailto:careers@vesparum.com?subject=Experienced%20Professional%20Candidate%20Enquiry">
                            careers@vesparum.com
                        </Link>
                        .
                    </StyledDescription>
                </SectionIntro>

                <SectionContent>
                    <AccordionWrapper isActive={inView} order={1}>
                        <Accordion readMoreCopy={readMoreCopyExperienced} invert setContentChanged={setContentChanged}>
                            <SubHeader invert>Experienced Professionals</SubHeader>
                            <SubmitAddress>
                                Our business continues to expand as our profile and reputation grow. <br />
                                <br />
                                We are seeking professionals with top-tier experience in investment banking, management
                                consulting, asset management, law and data science to join our growing team in
                                Melbourne.
                                <br />
                            </SubmitAddress>
                        </Accordion>
                    </AccordionWrapper>

                    <AccordionWrapper isActive={inView} order={2}>
                        <Accordion readMoreCopy={readMoreCopyInterns} invert setContentChanged={setContentChanged}>
                            <SubHeader invert>Internship Programs</SubHeader>
                            <SubmitAddress>
                                Vesparum’s Melbourne-based internship programs are our primary recruiting platform for
                                current university students. We offer a limited number of four-week winter and summer
                                internships to students who are in their penultimate or final years of study.
                                <br />
                            </SubmitAddress>
                        </Accordion>
                    </AccordionWrapper>
                </SectionContent>
            </StyledSectionContentWrapper>
        </Section>
    );
};

export default Positions;
