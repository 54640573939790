import React from 'react';
import styled from 'styled-components';

const ArrowLeft = styled.div`
    background: ${props => props.invert ? props.theme.graphite : props.theme.vivid};
    height: 12px;
    left: 5px;
    position: absolute;
    top: 3px;
    transform: rotate(45deg);
    width: 4px;
`

const ArrowRight = styled.div`
    background: ${props => props.invert ? props.theme.graphite : props.theme.vivid};
    height: 12px;
    left: 11px;
    position: absolute;
    top: 3px;
    transform: rotate(-45deg);
    width: 4px;
`

const ArrowWrapper = styled.div`
    height: 20px;
    position: relative;
    width: 20px;
    z-index:1;
`

const Arrow = ({ invert }) => (
    <ArrowWrapper>
        <ArrowLeft invert={invert} />
        <ArrowRight invert={invert}/>
    </ArrowWrapper>
);

export default Arrow;