import React, { useContext } from 'react';
import styled, { css } from 'styled-components';
import { Context } from '../../ContextState';
import Section from '../../components/Section';
import CtaButton from '../../components/CtaButton';
import {
    Description,
    Header,
    SubHeader,
    SectionContent,
    SectionContentWrapper,
    SectionIntro,
} from '../../components/StyledComponents';
import { ReactComponent as Independent } from '../../assets/images/icons/homepage/Independent.svg';
import { ReactComponent as DataDriven } from '../../assets/images/icons/homepage/DataDriven.svg';
import { ReactComponent as Trusted } from '../../assets/images/icons/homepage/Trusted.svg';

import { DEVICES } from '../../constants/breakpoints';

const StatementWrapper = styled.div`
    display: flex;

    @media ${DEVICES.TABLET} {
        opacity: 0;
        transform: translateY(20vh);
        transition: transform 0.6s ease-out, opacity 0.2s ease-out;

        /* Animate elements when section scrolls into view */
        ${props =>
            props.isActive &&
            css`
            opacity: 1;
            transform: translateY(0);
            transition: transform .6s ease-out, opacity .2s ease-out;

            ${props =>
                props.order === 1 &&
                css`
                    transition-delay: 0.4s;
                `}

            ${props =>
                props.order === 2 &&
                css`
                    transition-delay: 0.6s;
                `}

            ${props =>
                props.order === 3 &&
                css`
                    transition-delay: 0.8s;
                `}
        `}
    }
`;

const StatementCopy = styled.div`
    flex: 2;
    margin-left: 5%;

    @media ${DEVICES.LAPTOP} {
        margin-left: 10%;
    }
`;

const StyledHeader = styled(Header)`
    @media ${DEVICES.TABLET} {
        opacity: 0;
        transform: translateY(20vh);
        transition: transform 0.6s ease-out, opacity 0.2s ease-out;

        ${props =>
            props.isActive &&
            css`
                opacity: 1;
                transform: translateY(0);
                transition: transform 0.6s ease-out 0.2s, opacity 0.2s ease-out 0.2s;
            `}
    }
`;

const CtaButtonWrapper = styled.div`
    @media ${DEVICES.TABLET} {
        opacity: 0;
        transform: translateY(20vh);
        transition: transform 0.6s ease-out, opacity 0.2s ease-out;

        ${props =>
            props.isActive &&
            css`
                opacity: 1;
                transform: translateY(0);
                transition: transform 0.6s ease-out 0.4s, opacity 0.2s ease-out 0.4s;
            `}
    }
`;

const Attributes = () => {
    const { activeSection } = useContext(Context);
    const inView = activeSection.name === 'attributes';

    return (
        <Section displayName="attributes" name="attributes">
            <SectionContentWrapper>
                <SectionIntro>
                    <StyledHeader underline isActive={inView}>
                        Vesparum attributes
                    </StyledHeader>

                    <CtaButtonWrapper isActive={inView}>
                        <CtaButton to="/careers" color="vision">
                            Join our team
                        </CtaButton>
                    </CtaButtonWrapper>
                </SectionIntro>

                <SectionContent>
                    <StatementWrapper isActive={inView} order={1}>
                        <Trusted />
                        <StatementCopy>
                            <SubHeader>Trusted</SubHeader>
                            <Description>
                                Referrals are our primary source of new clients. We operate as trusted in-house advisors
                                to our clients, with long-term relationships always front of mind.
                            </Description>
                        </StatementCopy>
                    </StatementWrapper>

                    <StatementWrapper isActive={inView} order={2}>
                        <Independent />
                        <StatementCopy>
                            <SubHeader>Independent</SubHeader>
                            <Description>
                                We do not have the conflicts of interest typical of an integrated investment bank or
                                stockbroking firm. Our advice is always aligned with the collective best interests of
                                your shareholders.
                            </Description>
                        </StatementCopy>
                    </StatementWrapper>

                    <StatementWrapper isActive={inView} order={3}>
                        <DataDriven />
                        <StatementCopy>
                            <SubHeader>Data-driven</SubHeader>
                            <Description>
                                We leverage unique quantitative and qualitative data to optimise equity value and
                                shareholder outcomes.
                            </Description>
                        </StatementCopy>
                    </StatementWrapper>
                </SectionContent>
            </SectionContentWrapper>
        </Section>
    );
};

export default Attributes;
