import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { Context } from '../ContextState';
import TopBar from './TopBar';
import ScrollDownButton from './ScrollDownButton';
import ScrollTopButton from './ScrollTopButton';
import Cursor from './Cursor';
import SectionIndicator from './SectionIndicator';
import Modal from './Modal';
import { DEVICES } from '../constants/breakpoints';
import Background from '../assets/images/Background.svg';

const ContainerWrapper = styled.div`
    background-color: ${props => (props.fadeIn ? props.theme.vivid : props.theme.graphite)};
    color: ${props => props.theme.vivid};
    font-family: ${props => props.theme.font};
    font-size: 12pt;
    overflow: hidden;
    position: relative;
    transition: background 0.2s;
`;

const DottedBackground = styled.div`
    @media ${DEVICES.TABLET} {
        z-index: 0;
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-image: url('${Background}');
        background-attachment: fixed;
        background-position: left top;
        background-repeat: repeat;
        background-size: 25px;
    }
`;

const ContainerContent = styled.div`
    margin: 0 auto;
    max-width: ${props => (props.isLanding ? 'none' : '1024px')};
    width: 100%;
`;

const Container = ({ children }) => {
    const { activeSection } = useContext(Context);
    const isSectionInverted = activeSection.isInverted;
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    const inView = activeSection.name === 'hero';

    const isLanding = pathname === '/';

    return (
        <ContainerWrapper fadeIn={isSectionInverted}>
            <DottedBackground />
            <Cursor />
            <Modal />
            <TopBar />
            <SectionIndicator />
            <ScrollDownButton isVisible={!isLanding && inView} />
            <ScrollTopButton isVisible={!inView} invert={isSectionInverted} />
            <ContainerContent isLanding={isLanding}>{children}</ContainerContent>
        </ContainerWrapper>
    );
};

export default Container;
