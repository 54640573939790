import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import Fullpage, { FullPageSections, FullpageSection } from '@ap.cx/react-fullpage';
import WorkWithUs from './WorkWithUs';
import WhatWeOffer from './WhatWeOffer';
import Positions from './Positions';
import WhatWeValue from './WhatWeValue';
import Footer from '../../components/Footer';

const ScrollSection = styled(FullpageSection)`
    ${props =>
        !props.hero &&
        css`
            display: flex;
        `}
`;

const Careers = () => {
    const [isMobile, setMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        const detectScreenSize = () => {
            setMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', detectScreenSize);

        return () => window.removeEventListener('resize', detectScreenSize);
    });

    return isMobile ? (
        <>
            <WorkWithUs />
            <WhatWeValue />
            <WhatWeOffer />
            <Positions />
            <Footer visible />
        </>
    ) : (
        <Fullpage>
            <FullPageSections>
                <ScrollSection hero>
                    <WorkWithUs />
                </ScrollSection>
                <ScrollSection>
                    <WhatWeValue />
                </ScrollSection>
                <ScrollSection>
                    <WhatWeOffer />
                </ScrollSection>
                <ScrollSection>
                    <Positions />
                </ScrollSection>
                <ScrollSection>
                    <Footer />
                </ScrollSection>
            </FullPageSections>
        </Fullpage>
    );
};

export default Careers;
