import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import Fullpage, { FullPageSections, FullpageSection } from '@ap.cx/react-fullpage';
import Hero from './Hero';
import CapitalSolutions from './CapitalSolutions';
import AISolutions from './AISolutions';
import ContactUs from './ContactUs';
// import Toast from '../../components/Toast/Toast';

const ScrollSection = styled(FullpageSection)`
    ${props =>
        !props.hero &&
        css`
            display: flex;
        `}
`;

const InvestmentManagementHomepage = () => {
    const [isMobile, setMobile] = useState(window.innerWidth < 768);
    // const [messages, setMessages] = useState([]);

    // useEffect(() => {
    //     const newMessage = {
    //         id: '1',
    //         backgroundColor: '#353439',
    //     };
    //     setMessages([...messages, newMessage]);
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    useEffect(() => {
        const detectScreenSize = () => {
            setMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', detectScreenSize);

        return () => window.removeEventListener('resize', detectScreenSize);
    });

    return isMobile ? (
        <>
            <Hero />
            <CapitalSolutions />
            <AISolutions />
            <ContactUs />
            {/* <Toast toastList={messages} autoDelete={false} /> */}
        </>
    ) : (
        <Fullpage>
            <FullPageSections>
                <ScrollSection>
                    <Hero />
                </ScrollSection>

                <ScrollSection>
                    <CapitalSolutions />
                </ScrollSection>

                <ScrollSection>
                    <AISolutions />
                </ScrollSection>

                <ScrollSection>
                    <ContactUs />
                </ScrollSection>
            </FullPageSections>
            {/* <Toast toastList={messages} autoDelete={false} /> */}
        </Fullpage>
    );
};

export default InvestmentManagementHomepage;
