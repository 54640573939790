import React, { useContext } from 'react';
import styled, { css } from 'styled-components';
import { Context } from '../../ContextState';
import Section from '../../components/Section';
import { Header } from '../../components/StyledComponents';
import { ReactComponent as Number90 } from '../../assets/images/numbers/90.svg';
import { ReactComponent as Number80 } from '../../assets/images/numbers/80plus.svg';
import { ReactComponent as Number20 } from '../../assets/images/numbers/20plus.svg';
import { ReactComponent as Number50 } from '../../assets/images/numbers/50plus.svg';
import { DEVICES } from '../../constants/breakpoints';

const StyledHeader = styled(Header)`
    position: absolute;
    font-weight: normal;
    top: 60%;
    text-align: center;

    @media ${DEVICES.TABLET} {
        opacity: 0;
        transform: translateY(20vh);
        transition: transform 0.6s ease-out, opacity 0.2s ease-out;
    }
`;

const ContentWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

const Eighty = styled(Number80)`
    width: 100%;
    z-index: 0;

    @media ${DEVICES.TABLET} {
        opacity: 0;
        width: 80%;
    }

    @media ${DEVICES.LAPTOP} {
        width: initial;
    }
`;

const Twenty = styled(Number20)`
    width: 100%;
    z-index: 0;

    @media ${DEVICES.TABLET} {
        opacity: 0;
        width: 80%;
    }

    @media ${DEVICES.LAPTOP} {
        width: initial;
    }
`;

const Fifty = styled(Number50)`
    width: 100%;
    z-index: 0;

    @media ${DEVICES.TABLET} {
        opacity: 0;
        width: 80%;
    }

    @media ${DEVICES.LAPTOP} {
        width: initial;
    }
`;

const Ninety = styled(Number90)`
    width: 100%;
    z-index: 0;

    @media ${DEVICES.TABLET} {
        opacity: 0;
        width: 80%;
    }

    @media ${DEVICES.LAPTOP} {
        width: initial;
    }
`;
const TopHeader = styled(Header)`
    @media ${DEVICES.TABLET} {
        opacity: 0;
        transform: translateY(20vh);
        transition: transform 0.6s ease-out, opacity 0.2s ease-out;
        width: 40%;

        ${props =>
            props.isActive &&
            css`
                opacity: 1;
                transform: translateY(0);
                transition: transform 0.6s ease-out 0.2s, opacity 0.2s ease-out 0.2s;
            `}
    }
`;

const SectionMiddle = styled.div`
    justify-content: center;
    display: flex;
    margin-left: 0;
    position: relative;

    svg {
        height: 300px;
    }

    ${StyledHeader}, ${Eighty}, ${Twenty}, ${Fifty}, ${Ninety} {
        @media ${DEVICES.TABLET} {
            transform: translateY(20vh);
        }
    }

    ${props =>
        props.isActive &&
        css`
        ${StyledHeader}, ${Eighty}, ${Twenty}, ${Fifty}, ${Ninety} {
            opacity: 1;
		    transform: translateY(0);
		    transition: transform .6s ease-out, opacity .2s ease-out;
        }

        ${StyledHeader} {
            transition-delay: .${props =>
                props.order === 1 ? 5 : props.order === 2 ? 6 : props.order === 3 ? 7 : props.order === 4 ? 8 : 0}s;
        } 
        
        ${Eighty} {
            transition-delay: .4s;
        } 
        
        ${Twenty} {
            transition-delay: .5s;
        } 
        
        ${Fifty} {
            transition-delay: .6s;
        } 
        
        ${Ninety} {
            transition-delay: .7s;
        }
        
    `}
    
    @media ${DEVICES.TABLET} {
        width: 50%;
    }

    @media ${DEVICES.TABLET_LANDSCAPE} {
        width: 25%;

        & > svg {
            width: 80%;
        }

        ${StyledHeader} {
            font-size: 40px;
        }

        ${props =>
            (props.order === 2 || props.order === 4) &&
            css`
                margin-top: -100px;

                ${StyledHeader} {
                    top: 45%;
                }
            `}
    }
`;

const TrackRecord = () => {
    const { activeSection } = useContext(Context);
    const inView = activeSection.name === 'track-record';

    return (
        <Section displayName="track record" invert name="track-record">
            <TopHeader invert underline isActive={inView}>
                Our track record
            </TopHeader>

            <ContentWrapper>
                <SectionMiddle isActive={inView} align="left" order={1}>
                    <StyledHeader align="left" invert>
                        ASX-listed
                        <br />
                        clients
                    </StyledHeader>
                    <Eighty />
                </SectionMiddle>

                <SectionMiddle isActive={inView} align="right" order={2}>
                    <StyledHeader align="right" invert>
                        Private
                        <br />
                        clients
                    </StyledHeader>
                    <Twenty />
                </SectionMiddle>

                <SectionMiddle isActive={inView} align="left" order={3}>
                    <StyledHeader align="left" invert>
                        Capital
                        <br />
                        raisings
                    </StyledHeader>
                    <Fifty />
                </SectionMiddle>

                <SectionMiddle isActive={inView} align="right" order={4}>
                    <StyledHeader align="right" invert>
                        Clients from
                        <br />
                        referrals
                    </StyledHeader>
                    <Ninety />
                </SectionMiddle>
            </ContentWrapper>
        </Section>
    );
};

export default TrackRecord;
